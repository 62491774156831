import styles from './ReportTab.module.css'
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ExperimentReport from '../experimentReport/ExperimentReport'
import SkillReport from '../skill-report/SkillReport'
import WarReport from '../war-report/WarReport';
import BattleReport from '../battle-report/BattleReport'
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { Link } from 'react-router-dom';
import Sidebar from '../sidenav/Sidenav';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ReportTabMobile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to={'/profile'}>
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <h4 style={{ margin: '1.313rem 0.25rem' }}>Report</h4>
      <Tabs
        orientation='vertical' style={{ width: '100%', padding: '0' }}
      >
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap:'0.75rem', margin: '0 12px'}}>
        <Tab label="Skill Report" {...a11yProps(0)} className={styles.tabStyleMobile}
          sx={{
            '&.Mui-selected': {
              outline: 'none',
            },
          }}
          component={Link}
          to={'/skills-report'}
        />
        <Tab label="Experiment Report" {...a11yProps(1)} className={styles.tabStyleMobile}
          sx={{
            '&.Mui-selected': {
              outline: 'none',
            }
          }}
          component={Link}
          to={'/experiment-report'}
        />
        <Tab label="War Report" {...a11yProps(2)} className={styles.tabStyleMobile}
          sx={{
            '&.Mui-selected': {
              outline: 'none',
            }
          }}
          component={Link}
          to={'/war-report'}
        />
        <Tab label="Battle Report" {...a11yProps(3)} className={styles.tabStyleMobile}
          sx={{
            '&.Mui-selected': {
              outline: 'none',
            }
          }}
          component={Link}
          to={'/battle-report'}
        />
      </div>
      </Tabs>
    </>
  )
}

export default ReportTabMobile;