import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { readFile } from "../utils/utils";
import { useLocalStorage } from "@uidotdev/usehooks";
import styles from './ExperimentKey.module.css'

const ExperimentKey = () => {
  const [selectedLab, setSelectedLab] = useState("");
  const [selectedExp, setSelectedExp] = useState("");
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [iName, setIName] = useState("");
  const [uName, setUName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [labs, setLabs] = useState([]);
  const [currDate, setCurrDate] = useState(null);
  const [institutes, setInstitutes] = useState([]);
  const [experiments, setExperiments] = useState([]);
  const [duration, setDuration] = useState(60);
  const [tableData,setTableData]=useState([])
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [token,setToken] = useLocalStorage('token',null);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setUsers(response.data.data);
      } else {
        console.error("Error fetching users: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  const fetchLabs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/labs`
      );
      if (response.status !== 200) {
        toast.error("Error fetching lab data", response.data.message);
      }
      //   console.log(response.data.data);
      setLabs(response.data.data);
    } catch (error) {
      toast.error("Error fetching Labs", error);
    }
  };

  const fetchInstitutes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/getAll`
      );
      if (response.status !== 200) {
        toast.error("Error fetching lab data", response.data.message);
      }
      //   console.log(response.data.data);
      const temp = response.data.data.map((ele) => {
        return {
          label: ele.institute_name,
          institute_id: ele.institute_id,
        };
      });
      setInstitutes(temp);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching Institutes", error);
    }
  };

  const fetchExperiments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/experiments/${selectedLab}`
      );
      if (response.status === 404) {
        toast.error("No Experiments found", response.data.message);
        return;
      }
      if (response.status !== 200) {
        toast.error("Error fetching Experiments data", response.data.message);
      }
      console.log(response.data.data);
      setExperiments(response.data.data);
    } catch (error) {
      // console.log(error);
      if (error.response.status === 404) {
        setExperiments([]);
        toast.error(
          "No Experiments found for the given lab",
          error.response.data.message
        );
        return;
      }
      toast.error("Error fetching Experiments", error.response.data.message);
    }
  };

  const onGeneratePress = async(sendEmail=false) => {
    setTableData([])
    let fromDate;
    if (currDate === null) {
      fromDate = new Date();
    } else {
      fromDate = currDate;
    }

    // console.log(fromDate);
    if (duration === "" || duration) {
    }
    let toDate = new Date(fromDate.getTime() + duration * 60000);
    console.log(toDate);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/generateinstitutekey`,
        {
          labId:selectedLab,
          expId:selectedExp,
          from:fromDate,
          to:toDate,
          ins_id:selectedInstitute,
          names: selectedUsers.map(user => user.username),
          emails:selectedUsers.map(user=>{return{email:user.email,name:user.name}}),
          tosendEmail:sendEmail
        }
      )
      if (response.status===200) {
        toast.success(response.data.message)
        setTableData(response.data.userArray)
      }
      // console.log(response.data.userArray);
    } catch (error) {
        console.error(error);
        toast.error(error?.response?.data?.message)
    }
  };

  const onSendPress = async () => {
    let fromDate;
    if (currDate === null) {
      fromDate = new Date();
    } else {
      fromDate = currDate;
    }
  
    console.log(fromDate);
    if (duration === "" || duration) {
    }
    let toDate = new Date(fromDate.getTime() + duration * 60000);
    toDate = toDate.toISOString();
    console.log(toDate);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/generatesinglekey`,
        {
          labId:selectedLab,
          expId:selectedExp,
          from:fromDate,
          to:toDate,
          names:selectedUsers.map((user) => user.username), // Map all selected usernames
        }
      );
      console.log(response);
  
      if (response.status === 200) {
        toast.success(response.data.message);
        setTableData(response.data.userArray); // Set the table data with the fetched user array
      } else {
        toast.error("Failed to generate experiment key. Please try again later.");
      }
    } catch (error) {
        toast.error(error.response.data.message)
    }
  };

  const onExportPress=async()=>{
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/exportkeys`,
        {
          labId:selectedLab,
          expId:selectedExp,
          ins_id:selectedInstitute
        },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ExperimentKeys.xlsx");
      document.body.appendChild(link);
      link.click();
      console.log("File exported successfully");
    } catch (error) {
      const { data } = error.response;
      const file = await readFile(data);
      const { message } = JSON.parse(file); 
        toast.error(message)
    }
  }

  useEffect(() => {
    fetchLabs();
    fetchInstitutes();
    fetchUsers();
  }, []);

  const labChangeHandler = (e) => {
    console.log(e.target.value);
    setSelectedLab(e.target.value);
  };

  useEffect(() => {
    if (selectedLab) {
      fetchExperiments();
    }
  }, [selectedLab]);

  const dateChangeHandler = (value) => {
    setCurrDate(value.toDate());
  };
  //   console.log(selectedInstitute?.institute_id);

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        <Card elevation={3} className="col-md-12 p-3">
          <CardContent>
            <div className="filters">
              <h2 style={{ marginBottom: '2.564vw' }} className="page-header">
                Experiment Key
              </h2>
              <div className={styles.fieldsContainer}>
                <div className={styles.leftContainer}>
                  <TextField
                    id="Labs"
                    select
                    label="Labs"
                    fullWidth
                    value={selectedLab}
                    onChange={labChangeHandler}
                    className={styles.customTextfield}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                      // width:'32.051vw'
                    }}
                  >
                    {labs.map((lab) => {
                      return (
                        <MenuItem key={lab.lab_id} value={lab.lab_id} sx={{ minWidth: {xs: '100%', sm: '15rem', textOverflow: 'ellipsis', fontSize: '0.75rem'}}}>
                          {lab.lab_name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <TextField
                    id="Experiments"
                    select
                    label="Experiments"
                    fullWidth
                    value={selectedExp}
                    onChange={(e) => {
                      setSelectedExp(e.target.value);
                    }}
                    className={styles.customTextfield}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                      width:'32.051vw'
                    }}
                  >
                    {experiments.map((experiment) => {
                      return (
                        <MenuItem
                          key={experiment.experiment_id}
                          value={experiment.experiment_id}
                        >
                          {experiment.experiment_name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <Autocomplete
                    value={selectedInstitute}
                    onChange={(event, newValue) => {
                      setSelectedInstitute(newValue);
                    }}
                    options={institutes}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Institute Name"
                        className={styles.customTextfield}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: 'none',
                            },
                          },
                          width:'32.051vw'
                        }}
                      />
                    )}
                  />
                  <div className={styles.buttonContainer}>
                    <Button
                      variant="contained"
                      className="mr-3"
                      onClick={()=>{onGeneratePress()}}
                      style=
                      {{
                        height:'2.564vw',
                        width: '15.385vw',
                        backgroundColor: '#37446F',
                        textTransform: 'capitalize'
                      }}
                    >
                      Generate
                    </Button>
                    <Button
                      onClick={onExportPress}
                      variant="contained"
                      style=
                      {{
                        height:'2.564vw',
                        width: '15.385vw',
                        backgroundColor: '#37446F',
                        textTransform: 'capitalize'
                      }}
                      >
                      Export
                    </Button>
                  </div>
                </div>
                <div style={{height: '13vw', display: 'flex', alignItems: 'flex-end', fontWeight: '600'}}>
                  OR
                </div>
                <div className={styles.rightContainer}>
                  <FormControl
                    fullWidth
                    className={styles.customTextfield}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                      width:'32.051vw'
                    }}
                    >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="From"
                        onChange={dateChangeHandler}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <TextField
                    label="Duration (In Minutes)"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={duration}
                    onChange={(e) => {
                      setDuration(e.target.value);
                    }}
                    placeholder="Enter Duration"
                    className={styles.customTextfield}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                    }}
                  />
                    <Autocomplete
                      multiple
                      id="userId"
                      options={users}
                      getOptionLabel={(option) => option.username}
                      onChange={(event, value) => setSelectedUsers(value)}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          placeholder="Username"
                          className={styles.customTextfield}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                boxShadow: 'none',
                              },
                            },
                            margin: 0,
                            width: '32.051vw'
                          }}
                        />
                      )}
                    />
                  <Button variant="contained"
                    onClick={onSendPress}
                    style=
                    {{
                      height:'2.564vw',
                      width: '15.385vw',
                      backgroundColor: '#37446F',
                      textTransform: 'capitalize'
                    }}
                  >
                    Send
                  </Button>
                </div>
              </div>
              <div className="row">
              </div>
              <table className={styles.mainTable}>
                <thead className={styles.tableHead}>
                  <tr>
                    <th>S. No.</th>
                    <th style={{textAlign: 'left'}}>Name & Username</th>
                    <th>Lab Name</th>
                    <th>Experiment Name</th>
                    <th>Experiment Key</th>
                    <th>Expiry Time</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tableData.map((data,index)=>{
                      return(
                        <tr key={index}>
                          <td>{data.user_id}</td>
                          <td style={{textAlign: 'left'}}>{data.name} <br/> <div style={{fontSize:'0.897vw'}}> {data.username} </div></td>
                          <td>{data.labName}</td>
                          <td>{data.expName}</td>
                          <td>{data.experimentkey}</td>
                          <td>{data.duration} minutes</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ExperimentKey;
