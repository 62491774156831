import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga4';
import { useLocalStorage } from "@uidotdev/usehooks";
import { ArrowBackIosNewRounded } from "@mui/icons-material";

const Labs = () => {
  const [labs, setLabs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [replicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [replicateLabId, setReplicateLabId] = useState(null);
  const [replicateLabName, setReplicateLabName] = useState("");
  const [newLab, setNewLab] = useState({
    name: "",
    fee: 0,
    discountedFee: 0,
    description: "",
    labOrder: 0,
    paymentLink: "",
  });
  const [editLab, setEditLab] = useState({
    id: null,
    name: "",
    fee: 0,
    discountedFee: 0,
    description: "",
    labOrder: 0,
    paymentLink: "",
  });
  const navigate = useNavigate()
  const handleBack = () => {
    navigate('/dashboard')
  }
  const [token,setToken] = useLocalStorage('token',null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(3);

  const handleEdit = (lab) => {
    setEditLab(lab);
    setEditDialogOpen(true);
  };

  const handleEditLabs = async (labsId) => {
    ReactGA.event({
      category: 'Edit Labs',
      action: 'Edit Labs Button Clicked',
      label: 'Edit Labs',
    });
    try {
      const requestData = {
        labName: editLab.lab_name,
        description: editLab.description,
        labFee: editLab.lab_fee,
        discountedFee: editLab.discounted_fee,
        labOrder: editLab.lab_order,
        paymentLink: editLab.payment_link,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs/update/${labsId}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success(`Lab ${response.data.message}`);
        handleEditDialogClose();
        fetchLabs();
      } else {
        toast.error(`Error updating lab: ${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditLab({
      id: null,
      name: "",
      fee: 0,
      discountedFee: 0,
      description: "",
      labOrder: 0,
      paymentLink: "",
    });
  };

  const handleDelete = async (labsId) => {
    ReactGA.event({
      category: 'Delete Labs',
      action: 'Delete Labs Button Clicked',
      label: 'Delete Labs',
    });
    try {
      confirmAlert({
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this lab?',
        buttons: [
          {
            label: 'Yes',
            style: { backgroundColor: '#d32f2f', color: 'white' },
            onClick: async () => {
              try {
                const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs/delete/${labsId}`;
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
                const response = await axios.delete(apiUrl, config);

                if (response.status === 200) {
                  fetchLabs();
                  toast.success('Lab deleted successfully');
                } else {
                  console.error('Error deleting lab: ', response.data.message);
                  toast.error(`Error deleting lab: ${response.data.message}`);
                }
              } catch (error) {
                console.error('Error deleting lab: ', error);
                toast.error(`Error deleting lab: ${error.message}`);
              }
            },
          },
          {
            label: 'No',
            style: { backgroundColor: '#1976d2', color: 'white' },
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error('Error deleting lab: ', error);
      toast.error(`Error deleting lab: ${error.message}`);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewLab({
      name: "",
      fee: 0,
      discountedFee: 0,
      description: "",
      labOrder: 0,
      paymentLink: "",
    });
  };

  const handleAddNewLabs = async () => {
    try {
      const requestData = {
        labName: newLab.name,
        description: newLab.description,
        labFee: newLab.fee,
        discountedFee: newLab.discountedFee,
        labOrder: newLab.labOrder,
        paymentLink: newLab.paymentLink,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs/add`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        ReactGA.event({
          category: 'Add New Labs',
          action: 'Add New Labs Button Clicked',
          label: 'Add New Labs',
        });
        toast.success(`${response.data.message}`);
        handleCloseDialog();
        fetchLabs();
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const fetchLabs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setLabs(response.data.data);
      } else {
        console.error("Error fetching labs: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching labs: ", error);
    }
  };

  useEffect(() => {
    fetchLabs();
  }, [token]);

  const handleReplicate = (labId) => {
    setReplicateLabId(labId);
    setReplicateDialogOpen(true);
  };
  
  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
    setReplicateLabName("");
  };
  
  const handleReplicateSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/replicate/labs/${replicateLabId}`,
        { labName: replicateLabName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        handleReplicateDialogClose();
        fetchLabs();
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleExperimentButtonClick = () => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Experiment Button Clicked',
      label: 'Experiment Button',
    });
  };
  
  return (
    <div className="container-fluid" style={{marginTop: '6vw'}}>
      <div className="align-center-all full-height">
        {isAuthorized ? (
          <Card style={{boxShadow: "none"}} className="col-md-12 p-3">
            <CardContent>
              <div className="top-div">
                <div style={{display: 'flex', gap: '1vw', alignItems: 'baseline'}}>
                <ArrowBackIosNewRounded onClick={handleBack} style={{cursor: 'pointer'}}/>
                <div className="page-header">Labs</div>
                </div>
              <ToastContainer />
              <div>
                <Button
                  variant="contained"
                  onClick={handleOpenDialog}
                  className="mr-2 filled-button"
                >
                  Add
                </Button>
              </div>
              </div>
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>S. No.</th>
                    <th style={{textAlign: 'left'}}>Name</th>
                    <th>Fee</th>
                    <th>Discounted Fee</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {labs.map((lab) => (
                    <tr key={lab.lab_id}>
                      <td>{lab.lab_id}</td>
                      <td style={{textAlign: 'left'}}>{lab.lab_name}</td>
                      <td>{lab.lab_fee}</td>
                      <td>{lab.discounted_fee}</td>
                      <td>
                        <Button
                          variant="contained"
                          className="mr-2 g-but"
                          onClick={() => handleEdit(lab)}
                        >
                          Edit
                        </Button>
                        <Link to={`/labs/${lab.lab_id}/experiments`}>
                          <Button
                            variant="contained"
                            className="mr-2 b-but"
                            onClick={handleExperimentButtonClick}
                          >
                            Experiment
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          className="mr-2 y-but"
                          onClick={() => handleReplicate(lab.lab_id)}
                        >
                          Replicate
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleDelete(lab.lab_id)}
                          className="r-but"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                PaperProps={{
                  sx: {
                    padding: '1vw',
                    width: '35.128vw',
                  }
                }}
              >
                <DialogTitle className="pb-0 dialog-title">Add New Labs</DialogTitle>
                <form className="m-2">
                  <TextField
                    label="New Lab Name"
                    value={newLab.name}
                    onChange={(e) =>
                      setNewLab({ ...newLab, name: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Description"
                    value={newLab.description}
                    onChange={(e) =>
                      setNewLab({ ...newLab, description: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Fee"
                    type="number"
                    value={newLab.fee}
                    onChange={(e) =>
                      setNewLab({ ...newLab, fee: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Discounted Fee"
                    type="number"
                    value={newLab.discountedFee}
                    onChange={(e) =>
                      setNewLab({
                        ...newLab,
                        discountedFee: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Lab Order"
                    type="number"
                    value={newLab.labOrder}
                    onChange={(e) =>
                      setNewLab({ ...newLab, labOrder: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  {/* <TextField
                    label="Payment Link"
                    value={newLab.paymentLink}
                    onChange={(e) =>
                      setNewLab({ ...newLab, paymentLink: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                  /> */}
                  <div className="dialog-actions-box">
                  <Button
                    variant="outlined"
                    onClick={handleCloseDialog}
                    className="mt-2 mr-1 outlined-button"
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleAddNewLabs}
                    className="mt-2  filled-button"
                  >
                    Add
                  </Button>
                  </div>
                </form>
              </Dialog>

              <Dialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                PaperProps={{
                  sx: {
                    padding: '1vw',
                    width: '35.128vw',
                  }
                }}
              >
                <DialogTitle className="pb-0 dialog-title">Edit Lab</DialogTitle>
                <form className="m-2">
                  <TextField
                    label="Lab Name"
                    value={editLab.lab_name}
                    onChange={(e) =>
                      setEditLab({ ...editLab, lab_name: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Description"
                    value={editLab.description}
                    onChange={(e) =>
                      setEditLab({
                        ...editLab,
                        description: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Fee"
                    type="number"
                    value={editLab.lab_fee}
                    onChange={(e) =>
                      setEditLab({ ...editLab, lab_fee: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Discounted Fee"
                    type="number"
                    value={editLab.discounted_fee}
                    onChange={(e) =>
                      setEditLab({
                        ...editLab,
                        discounted_fee: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <TextField
                    label="Lab Order"
                    type="number"
                    value={editLab.lab_order}
                    onChange={(e) =>
                      setEditLab({
                        ...editLab,
                        lab_order: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  {/* <TextField
                    label="Payment Link"
                    value={editLab.payment_link}
                    onChange={(e) =>
                      setEditLab({
                        ...editLab,
                        payment_link: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                  /> */}
                  <div className="dialog-actions-box">
                  <Button
                    variant="outlined"
                    onClick={handleEditDialogClose}
                    className="mt-2 mr-1 outlined-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleEditLabs(editLab.lab_id)}
                    className="mt-2 filled-button"
                  >
                    Save
                  </Button>
                  </div>
                </form>
              </Dialog>
              <Dialog
                open={replicateDialogOpen}
                onClose={handleReplicateDialogClose}
                PaperProps={{
                  sx: {
                    padding: '1vw',
                    width: '35.128vw',
                  }
                }}
              >
                <DialogTitle className="pb-0 dialog-title">Replicate Lab</DialogTitle>
                <form className="m-2">
                  <TextField
                    label="Lab Name"
                    value={replicateLabName}
                    onChange={(e) => setReplicateLabName(e.target.value)}
                    fullWidth
                    margin="normal"
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}

                  />
                  <div className="dialog-actions-box">
                  <Button
                    variant="outlined"
                    onClick={handleReplicateDialogClose}
                    className="mt-2 mr-1 outlined-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleReplicateSubmit}
                    className="mt-2 filled-button"
                  >
                    Replicate
                  </Button>
                  </div>
                </form>
              </Dialog>
            </CardContent>
          </Card>
        ) : (
          <h2>You are unauthorized to access this page.</h2>
        )}
      </div>
    </div>
  );
};

export default Labs;