import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import {
  TablePagination,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
} from '@mui/material';
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CardContent from "@mui/material/CardContent";
import { useLocalStorage } from "@uidotdev/usehooks";
import Sidebar from "../sidenav/Sidenav";
import { ReactComponent as UserIcon } from "../assets/icons/UserIcon.svg";
import { ReactComponent as Hemberger } from "../assets/icons/Hemberger.svg";
import { Link } from "react-router-dom";

const QrCodeMobile = () => {
  const [qrCode, setQrCode] = useState([]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];

  const [token, setToken] = useLocalStorage('token', null);

  const formatDate = (date) => {
    date = new Date(date);
    let ISTDate = new Date(date.getTime());

    let day = ISTDate.getDate();
    let month = ISTDate.getMonth() + 1;
    let year = ISTDate.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    let formattedDate = `${day}.${month}.${year}`;

    let hours = ISTDate.getHours();
    let minutes = ISTDate.getMinutes();
    let AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let formattedTime = `${hours}:${minutes}${AmOrPm}`;
    return { time: formattedTime, date: formattedDate };
  };

  const fetchQrCode = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/qr-code/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setQrCode(response.data.data);
      } else {
        console.error("Error fetching qrCode: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching qrCode: ", error);
    }
  };

  useEffect(() => {
    fetchQrCode();
  }, [token]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  }
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  /* MUI Table */
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" scope="row">
            <div>{row.name}</div>
            <div>{row.vendor}</div>
          </TableCell>
          <TableCell align="right">
            <div>{formatDate(row.created_at).date}</div>
            <div>{formatDate(row.created_at).time}</div>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              style={{ color: 'black' }}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit  >
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '0.625rem', marginTop: '4px', marginBottom: '4px' }}>
                <div>Email: {row.email}</div>
                <div>Mobile: {row.phone}</div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to="/profile">
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />

      <div className="container-fluid" style={{padding: '0'}}>
        <h4 style={{ margin: '0.75rem' }}>QR Code Leads</h4>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 20, 30]}
          count={qrCode.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* MUI Table */}
        <Table aria-label="collapsible table" sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
          }
        }}>
          <TableHead style={{ height: '2.75rem', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
            <TableRow>
              <TableCell>Name & Vendor</TableCell>
              <TableCell align="right">Date & Time</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {qrCode.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((inquiry) => (
                <Row key={inquiry.qr_id} row={inquiry} />
              ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default QrCodeMobile;