import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    List,
    ListItem,
    ListItemText,
    Collapse,
    IconButton
  } from "@mui/material";
  import {
    DatePicker,
    DateTimePicker,
    LocalizationProvider,
  } from "@mui/x-date-pickers";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import axios from "axios";
  import React, { lazy, Suspense, useEffect, useState } from "react";
  import { toast } from "react-toastify";
  import { readFile } from "../utils/utils";
  import { useLocalStorage } from "@uidotdev/usehooks";
  import styles from './ExpKeyMobile.module.css'
  import isDesktop from "../isDesktop";
  import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
  import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
  import Sidebar from '../sidenav/Sidenav';
  import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
  import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
  import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
  import { tableCellClasses } from "@mui/material/TableCell";

  const DesktopComponent = lazy(() => import("./ExperimentKey"));

  const ExpKeyMobile = () => {
    const [selectedLab, setSelectedLab] = useState("");
    const [selectedExp, setSelectedExp] = useState("");
    const [selectedInstitute, setSelectedInstitute] = useState(null);
    const [iName, setIName] = useState("");
    const [uName, setUName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [labs, setLabs] = useState([]);
    const [currDate, setCurrDate] = useState(null);
    const [institutes, setInstitutes] = useState([]);
    const [experiments, setExperiments] = useState([]);
    const [duration, setDuration] = useState(60);
    const [tableData,setTableData]=useState([])
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [token,setToken] = useLocalStorage('token',null);
    const isDesktopDevice = isDesktop();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [logoutDialog, setLogoutDialog] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const closeSidebar = () => {
      setIsSidebarOpen(false);
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/user/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response.status === 200) {
          setUsers(response.data.data);
        } else {
          console.error("Error fetching users: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    };
  
    const fetchLabs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/labs`
        );
        if (response.status !== 200) {
          toast.error("Error fetching lab data", response.data.message);
        }
        //   console.log(response.data.data);
        setLabs(response.data.data);
      } catch (error) {
        toast.error("Error fetching Labs", error);
      }
    };
  
    const fetchInstitutes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/getAll`
        );
        if (response.status !== 200) {
          toast.error("Error fetching lab data", response.data.message);
        }
        //   console.log(response.data.data);
        const temp = response.data.data.map((ele) => {
          return {
            label: ele.institute_name,
            institute_id: ele.institute_id,
          };
        });
        setInstitutes(temp);
      } catch (error) {
        console.log(error);
        toast.error("Error fetching Institutes", error);
      }
    };
  
    const fetchExperiments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/experiments/${selectedLab}`
        );
        if (response.status === 404) {
          toast.error("No Experiments found", response.data.message);
          return;
        }
        if (response.status !== 200) {
          toast.error("Error fetching Experiments data", response.data.message);
        }
        console.log(response.data.data);
        setExperiments(response.data.data);
      } catch (error) {
        // console.log(error);
        if (error.response.status === 404) {
          setExperiments([]);
          toast.error(
            "No Experiments found for the given lab",
            error.response.data.message
          );
          return;
        }
        toast.error("Error fetching Experiments", error.response.data.message);
      }
    };
  
    const onGeneratePress = async(sendEmail=false) => {
      setTableData([])
      let fromDate;
      if (currDate === null) {
        fromDate = new Date();
      } else {
        fromDate = currDate;
      }
  
      // console.log(fromDate);
      if (duration === "" || duration) {
      }
      let toDate = new Date(fromDate.getTime() + duration * 60000);
      console.log(toDate);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/generateinstitutekey`,
          {
            labId:selectedLab,
            expId:selectedExp,
            from:fromDate,
            to:toDate,
            ins_id:selectedInstitute,
            names: selectedUsers.map(user => user.username),
            emails:selectedUsers.map(user=>{return{email:user.email,name:user.name}}),
            tosendEmail:sendEmail
          }
        )
        if (response.status===200) {
          toast.success(response.data.message)
          setTableData(response.data.userArray)
        }
        console.log(response.data.userArray);
      } catch (error) {
          console.error(error);
          toast.error(error?.response?.data?.message)
      }
    };
  
    const onSendPress = async () => {
      let fromDate;
      if (currDate === null) {
        fromDate = new Date();
      } else {
        fromDate = currDate;
      }
    
      console.log(fromDate);
      if (duration === "" || duration) {
      }
      let toDate = new Date(fromDate.getTime() + duration * 60000);
      toDate = toDate.toISOString();
      console.log(toDate);
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/generatesinglekey`,
          {
            labId:selectedLab,
            expId:selectedExp,
            from:fromDate,
            to:toDate,
            names:selectedUsers.map((user) => user.username), // Map all selected usernames
          }
        );
        console.log(response);
    
        if (response.status === 200) {
          toast.success(response.data.message);
          setTableData(response.data.userArray); // Set the table data with the fetched user array
        } else {
          toast.error("Failed to generate experiment key. Please try again later.");
        }
      } catch (error) {
          toast.error(error.response.data.message)
      }
    };
  
    const onExportPress=async()=>{
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/expkey/exportkeys`,
          {
            labId:selectedLab,
            expId:selectedExp,
            ins_id:selectedInstitute
          },
          {
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ExperimentKeys.xlsx");
        document.body.appendChild(link);
        link.click();
        console.log("File exported successfully");
      } catch (error) {
        const { data } = error.response;
        const file = await readFile(data);
        const { message } = JSON.parse(file); 
          toast.error(message)
      }
    }
  
    useEffect(() => {
      fetchLabs();
      fetchInstitutes();
      fetchUsers();
    }, []);
  
    const labChangeHandler = (e) => {
      console.log(e.target.value);
      setSelectedLab(e.target.value);
    };
  
    useEffect(() => {
      if (selectedLab) {
        fetchExperiments();
      }
    }, [selectedLab]);
  
    const dateChangeHandler = (value) => {
      setCurrDate(value.toDate());
    };
    //   console.log(selectedInstitute?.institute_id);
  
    function formatDate(dateTimeString) {
      const date = new Date(dateTimeString);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
      const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
      return `${formattedDate} ${formattedTime}`;
    }

    function Row(props) {
      const { row } = props;
      const [open, setOpen] = React.useState(false);
    
      return (
        <React.Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell component="th" scope="row">
              <div>{row?.name}</div>
              <div>{row?.username}</div>
            </TableCell>
            <TableCell align="right">
              <div>{row?.experimentkey}</div>
              <div>{row?.duration}</div>
            </TableCell>
            <TableCell>
              <IconButton
                aria-label="expand row"
                style={{ color: 'black' }}
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black', }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '10px', marginTop: '4px', marginBottom: '4px' }}>
                  <div>Lab Name: {row?.labName}</div>
                  <div>Experiment Name: {row?.expName}</div>
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    }
  
    return (
      <Suspense>
      {isDesktopDevice ? (
        <DesktopComponent />
      ) : (
      <div className="container-fluid mt-2 pb-3">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0rem 0.75rem 1.25rem 0.75rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
                  <Hemberger onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
                </div>
                <UserIcon to={'/profile'} />
              </div>
              <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
        <div className="align-center-all full-height">
          <Card style={{boxShadow: 'none'}} className="col-md-12 p-0">
            <CardContent className="p-0">
              <div className="filters">
                <h2 className="page-header">
                  Experiment Key
                </h2>
                <div className="fieldContainer">
                  <div className="leftFields">
                    <TextField
                      id="Labs"
                      select
                      label="Labs"
                      fullWidth
                      value={selectedLab}
                      onChange={labChangeHandler}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              minWidth: '18rem',
                              left: '0',
                            },
                          },
                        },
                      }}
                    >
                      {labs.map((lab) => {
                        return (
                          <MenuItem key={lab.lab_id} value={lab.lab_id}>
                            {lab.lab_name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      id="Experiments"
                      select
                      label="Experiments"
                      fullWidth
                      value={selectedExp}
                      onChange={(e) => {
                        setSelectedExp(e.target.value);
                      }}
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              minWidth: '18rem',
                              left: '0',
                            },
                          },
                        },
                      }}
                    >
                      {experiments.map((experiment) => {
                        return (
                          <MenuItem
                            key={experiment.experiment_id}
                            value={experiment.experiment_id}
                          >
                            {experiment.experiment_name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <FormControl
                      fullWidth
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        },
                      }}
                      >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Date"
                          onChange={dateChangeHandler}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <TextField
                      label="Duration (In Minutes)"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                      placeholder="Enter Duration"
                      className="customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        },
                      }}
                    />
                    <Autocomplete
                      value={selectedInstitute}
                      onChange={(event, newValue) => {
                        setSelectedInstitute(newValue);
                      }}
                      options={institutes}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id || value === null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Institute Name"
                          className="customTextfield"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                boxShadow: 'none',
                              },
                            },
                          }}
                        />
                      )}
                    />
                    <div className={styles.buttonContainer}>
                      <Button
                        variant="contained"
                        className="filled-button"
                        onClick={()=>{onGeneratePress()}}
                      >
                        Generate
                      </Button>
                      <Button
                        onClick={onExportPress}
                        variant="contained"
                        className="filled-button"
                        >
                        Export
                      </Button>
                    </div>
                  </div>
                  <div style={{ display: 'flex', fontWeight: '600', justifyContent: 'center'}}>
                    OR
                  </div>
                  <div className="rightFields">
                      <Autocomplete
                        multiple
                        id="userId"
                        options={users}
                        getOptionLabel={(option) => option.username}  
                        onChange={(event, value) => setSelectedUsers(value)}
  
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            placeholder="Username"
                            className="customTextfield"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                  borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'transparent',
                                  boxShadow: 'none',
                                },
                              },
                              p:'0.75rem'
                            }}
                          />
                        )}
                      />
                    <Button variant="contained"
                      onClick={onSendPress}
                      className="full-but mb-3"
                    >
                      Send
                    </Button>
                  </div>
                </div>
                <div className="row">
                </div>
                {/* <table className={styles.mainTable}>
                  <thead className={styles.tableHead}>
                    <tr>
                      <th style={{textAlign: 'left', fontSize: '0.75rem'}}>Name & Username</th>

                      <th style={{fontSize: '0.75rem', textAlign: 'right'}}>Experiment Key & Expiry Time</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableData.map((data,index)=>{
                        return(
                          <tr key={index}>
                            <td style={{textAlign: 'left', fontSize: '0.75rem'}}>
                              {data.name}
                              <br/>
                              <div style={{fontSize:'0.625rem'}}> {data.username} </div>
                            </td>
                            <td style={{ fontSize: '0.75rem', textAlign: 'right'}}>
                                {data.experimentkey}
                                <br />
                                <div style={{ fontSize: '0.625rem' }}>{data.duration} minutes</div>
                            </td>
                            <td>
                              <ArrowDropDownIcon onClick={handleDropdownToggle} />
                                <List component="nav" aria-label="submenu" sx={{p:0}} className={isDropdownOpen ? styles.dropdownOpen : styles.dropdownClosed}>
                                  <ListItem>
                                    <ListItemText>
                                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                      <div style={{fontSize: '0.625rem'}}>
                                      Lab Name: {data.labName}
                                      </div>
                                      <div style={{fontSize: '0.625rem'}}>
                                      Experiment Name: {data.expName}
                                      </div>
                                      </div>
                                    </ListItemText>
                                  </ListItem>
                                </List>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table> */}
                      <Table aria-label="collapsible table" sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none"
                        }
                      }}>
                        <TableHead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                          <TableRow>
                            <TableCell>Name & Username</TableCell>
                            <TableCell align="right">Experiment Key & Expiry Time</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData.map((row, index) => (
                            <Row key={index} row={row} />
                          ))}
                        </TableBody>
                      </Table>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
        )}
      </Suspense>
    );
  };
  
  export default ExpKeyMobile;
  