import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import InquiriesMobile from "./InquiriesMobile";
import isDesktop from "../isDesktop";
import styles from './Inquiries.module.css';
import { useLocalStorage } from "@uidotdev/usehooks";

const Inquiries = () => {
  const isDesktopDevice = isDesktop();
  const [inquiries, setInquiries] = useState([]);
  const [filteredInquiries, setFilteredInquiries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(2);
  const [token, setToken] = useLocalStorage('token', null);

  function formatDateAndTime(date) {
    date = new Date(date);
    let ISTDate = new Date(date.getTime());

    let day = ISTDate.getDate();
    let month = ISTDate.getMonth() + 1;
    let year = ISTDate.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    let formattedDate = `${day}.${month}.${year}`;

    // Format the time in HH.MM am/pm
    let hours = ISTDate.getHours();
    let minutes = ISTDate.getMinutes();
    let AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let formattedTime = `${hours}:${minutes}${AmOrPm}`;
    return { time: formattedTime, date: formattedDate };
  }

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/contact-us`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          const sortedData = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setInquiries(sortedData);
          setFilteredInquiries(sortedData);
        } else {
          console.error("Error fetching inquiries: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching inquiries: ", error);
      }
    };

    isDesktopDevice && fetchInquiries();
  }, [token]);

  useEffect(() => {
    isDesktopDevice && applyFilters();
  }, [searchQuery, fromDate, toDate]);

  const applyFilters = () => {
    const filteredData = inquiries.filter((inquiry) => {
      const { name, email, phone, created_at } = inquiry;
      const query = searchQuery.toLowerCase();
      const inquiryDate = new Date(created_at);
      const isWithinDateRange =
        (!fromDate || inquiryDate >= new Date(fromDate)) &&
        (!toDate || inquiryDate <= new Date(toDate));
      return (
        (name.toLowerCase().includes(query) ||
          email.toLowerCase().includes(query) ||
          phone.toLowerCase().includes(query)) &&
        isWithinDateRange
      );
    });

    setFilteredInquiries(filteredData);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  return (
    <>
      {
        isAuthorized ? (
          isDesktopDevice ? (
            <div className="container-fluid" style={{ paddingTop: '3.6vw' }}>
              <div className="align-center-all full-height">
                <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                  <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h2>Enquiries</h2>
                      <div className={styles.filterForm}>
                        <TextField
                          style={{ marginRight: "1vw" }}
                          value={searchQuery}
                          onChange={handleSearchChange}
                          variant="outlined"
                          placeholder="Search"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                boxShadow: 'none',
                              },
                            },
                          }}
                          InputProps={{
                            style: {
                              height: '2.564vw',
                              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                            },
                            endAdornment: <SearchIcon />,
                          }}
                          margin="normal"
                        />
                        <TextField
                          label="From Date"
                          type="date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          InputProps={{ style: { height: '2.564vw' } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          style={{ marginRight: "1vw" }}
                        />
                        <TextField
                          label="To Date"
                          type="date"
                          value={toDate}
                          onChange={handleToDateChange}
                          InputProps={{ style: { height: '2.564vw' } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                        />
                      </div>
                    </div>
                    <table className="table">
                      <thead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                        <tr>
                          <th className={styles.thfirstChild}>S. No.</th>
                          <th style={{ textAlign: 'left' }}>Name & Username</th>
                          <th style={{ textAlign: 'left' }}>Email</th>
                          <th>Mobile</th>
                          <th>Date & Time</th>
                          <th className={styles.thlastChild} style={{ textAlign: 'left' }}>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredInquiries.map((inquiry) => (
                          <tr key={inquiry.msg_id}>
                            <td>{inquiry.msg_id}</td>
                            <td style={{ textAlign: 'left' }}>
                              <>
                                <p className={styles.paraNoMarginPadding} style={{ padding: '0', margin: '0' }}>{inquiry.name}</p>
                                <p className={styles.paraNoMarginPadding} style={{ padding: '0', margin: '0' }}>{inquiry.username}</p>
                              </>

                            </td>
                            <td style={{ textAlign: 'left' }}>{inquiry.email}</td>
                            <td>{inquiry.phone}</td>
                            <td>
                              <>
                                <p className={styles.paraNoMarginPadding}>{formatDateAndTime(inquiry.created_at).date}</p>
                                <p className={styles.paraNoMarginPadding}>{formatDateAndTime(inquiry.created_at).time}</p>
                              </>
                            </td>
                            <td style={{ textAlign: 'left' }}>{inquiry.message}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardContent>
                </Card>
              </div>
            </div>
          ) : (
            <InquiriesMobile />
          ) 
        ) : (
          <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page.</h2>
        )
    }
    </>
  );
};

export default Inquiries;