import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import { Button } from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';

const ExperimentExercise = ({ onQuestionsAdded, questionType, experimentId }) => {
  const [token,setToken]=useLocalStorage("token",null)
  const [selectedFile, setSelectedFile] = useState(null);

  const trackEvent = (action) => {
    ReactGA.event({
      category: 'Experiment Exercise',
      action: action,
    });
  };

  const handleAdd = async () => {
    try {
      const formData = new FormData();
      formData.append('excelFile', selectedFile);
      formData.append('questionType', questionType);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/warExercise/experiment/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Questions added successfully');
      const insertedQuestionIds = response.data.insertedQuestionIds;
      onQuestionsAdded(insertedQuestionIds);
      trackEvent('Add Questions');
      toast.success('Questions added successfully');
    } catch (error) {
      console.error('Error adding questions:', error);
      toast.error('Error adding questions');
    }
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append('excelFile', selectedFile);

      const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/warExercise/experiment/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response);

      const insertedQuestionIds = [];
      console.log('Questions updated successfully');
      onQuestionsAdded(insertedQuestionIds);
      trackEvent('Update Questions');
      toast.success('Questions updated successfully');
    } catch (error) {
      console.error('Error updating questions:', error);
      toast.error('Error updating questions');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
      <input type="file" onChange={handleFileChange} />
      <div className='pt-3'>
      <Button onClick={handleUpdate} variant='contained' className='mr-2 filled-button'>Update Questions</Button>
      <Button onClick={handleAdd} variant='contained' className='filled-button'>Add Questions</Button>
      </div>
    </div>
  );
};

export default ExperimentExercise;