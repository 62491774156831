import axios from "axios";

export const registerServiceWorker = async (
  setCurrSubscription = () => {},
  setCurRegistration = () => {},
  publicVapidKey
) => {
  // console.log(publicVapidKey);

  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/service-worker.js",
        {
          scope: "/",
        }
      );
      setCurRegistration(registration);
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }

      //     if ('PeriodicSyncManager' in window) {
      //         navigator.serviceWorker.ready.then((registration) => {
      //             registration.periodicSync.register('checkConditions', {
      //                 minInterval:  60 * 1000, // 24 hours
      //                 powerState: 'any',
      //                 networkState: 'any'
      //             }).then(() => {
      //                 console.log('Periodic Sync registered');
      //             }).catch((error) => {
      //                 console.error('Periodic Sync registration failed:', error);
      //             });
      //         });
      //   };
      // console.log(registration);

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicVapidKey,
      });
      console.log(subscription);

      // setCurrSubscription(subscription)
      await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/notification/subscribe`,
        {
          subscription:subscription,
          // userType:"EMPLOYEE",
          empId:localStorage.getItem('userId')
        }
      );
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }else{
    console.log("Browser doesn't support service worker");
    
  }
};
