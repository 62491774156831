import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import axios from "axios";
import ExperimentExercise from "./ExperimentExercise";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga4';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useLocalStorage } from "@uidotdev/usehooks";
import { ArrowBackIosNewRounded } from "@mui/icons-material";

const trackEvent = (action) => {
  ReactGA.event({
    category: 'Experiment Exercise',
    action: action,
  });
};

const Experiment = () => {
  const { labId } = useParams();
  const [experiments, setExperiments] = useState([]);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [insertedQuestionIds, setInsertedQuestionIds] = useState([]);
  const [isReplicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [showConcepts,setShowConcepts]=useState(false)
  const [conceptList,setConceptList]=useState([])
  const [labs, setLabs] = useState([]);
  const  [selectedLabName, setSelectedLabName] = useState("");

  const navigate = useNavigate()
  const handleBack = () => {
    navigate(`/labs`)
  }
  const [formData, setFormData] = useState({
    experiment_id: "",
    experiment_name: "",
    experiment_order: "",
    question_type: "",
    marks_per_question: "",
    negative_marks: "",
    no_of_questions: "",
    description: "",
    instructions: "",
    max_time: "",
    isVisible: 0,
    coin_type: "",
    clear_score: "",
    concepts: ""
  });
  const [replicateFormData, setReplicateFormData] = useState({
    newExperimentName: "",
  });
  const [currentExperimentId, setCurrentExperimentId] = useState(null);

  const [token,setToken] = useLocalStorage('token',null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(3);

  const trackExperimentAction = (action) => {
    ReactGA.event({
      category: 'Experiment',
      action: action,
    });
  };

  useEffect(() => {
    fetchExperiments();
  }, []);

  const fetchListHandler=async(expId)=>{
    if (showConcepts) {
      setShowConcepts(false)
      return;
    }
    setShowConcepts(true)
    try {
      const response=await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/concepts/${expId}`)

      if (response.status===200) {
        setConceptList(response.data.data.join(" | "))
      }


    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  const fetchExperiments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/all/${labId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setExperiments(response.data.data);
      } else {
        console.error("Error fetching experiments:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching experiments:", error);
    }
  };

  const fetchLabs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const labsData =  response.data.data
        setLabs(labsData);
        console.log(labsData);
        const selectedLab = labsData.find(lab => lab.lab_id === parseInt(labId, 10));
        selectedLabName(selectedLab ? selectedLab.lab_name : "Unknown Lab");
      } else {
        console.error("Error fetching labs: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching labs: ", error);
      // setLabs([])
      // setSelectedLabName("unknown lab")
    }
  };

  useEffect(() => {
    fetchLabs();
  }, [token]);

  const inputFields = [
    {
      label: "Experiment ID",
      name: "experiment_id",
      type: "number",
      disabled: true,
    },
    { label: "Experiment Order", name: "experiment_order", type: "number" },
    { label: "New Experiment Name", name: "experiment_name" },
    { label: "Marks/Question", name: "marks_per_question", type: "number" },
    { label: "No. of Questions", name: "no_of_questions", type: "number" },
    { label: "Max Time", name: "max_time", type: "time" },
    { label: "Negative Marks", name: "negative_marks", type: "number" },
    { label: "Clear Score", name: "clear_score", type: "number" },
    { label: "Coin Type", name: "coin_type" },
    { label: "Description", name: "description", type: "multiline" },
    { label: "Instructions", name: "instructions", type: "multiline" },
    { label: "Experiment Start", name: "isVisible", type: "toggle" },
    { label: "Concepts", name: "concepts", type: "number" },
  ];

  const handleAdd = () => {
    setFormData({
      experiment_id: "",
      experiment_name: "",
      experiment_order: "",
      question_type: "",
      marks_per_question: "",
      negative_marks: "",
      no_of_questions: "",
      description: "",
      instructions: "",
      max_time: "",
      isVisible: 0,
      coin_type: "",
      clear_score: "",
      concepts: ""
    });
    trackExperimentAction('Experiment Added');
    setAddDialogOpen(true);
  };

  const handleEdit = (experimentId) => {
    const experimentToEdit = experiments.find(
      (experiment) => experiment.experiment_id === experimentId
    );
    console.log(experimentToEdit);
    let orderedConcepts;
    if (experimentToEdit.concepts!==null) {
      orderedConcepts=experimentToEdit.concepts.slice(1,experimentToEdit.concepts.length-1).split(",")
    }else{
      orderedConcepts=[]
    }
    
    orderedConcepts=orderedConcepts.map((ele)=>{
      let temp= ele.trim()
      temp=temp.slice(1,temp.length-1);
      return temp;
    })
    orderedConcepts=orderedConcepts.join(',')
    // console.log(orderedConcepts);
    setFormData({
      experiment_id: experimentToEdit.experiment_id,
      experiment_name: experimentToEdit.experiment_name,
      experiment_order: experimentToEdit.experiment_order,
      question_type: experimentToEdit.question_type||"lorem",
      marks_per_question: experimentToEdit.marks_per_question,
      negative_marks: experimentToEdit.negative_marks||0,
      no_of_questions: experimentToEdit.no_of_questions,
      description: experimentToEdit.description,
      instructions: experimentToEdit.instructions,
      max_time: experimentToEdit.max_time,
      isVisible: experimentToEdit.isVisible,
      coin_type: experimentToEdit.coin_type,
      clear_score: experimentToEdit.clear_score,
      concepts: orderedConcepts
    });

    trackExperimentAction('Experiment Edited');
    setAddDialogOpen(true);
  };

  const handleOpenReplicateDialog = (experimentId) => {
    setReplicateFormData({
      newLabId: replicateFormData.newLabId,
      oldLabId: labId,
      newExperimentName: replicateFormData.newExperimentName,
    });

    setReplicateDialogOpen(true);
  };

  const handleReplicate = (experimentId) => {
    setCurrentExperimentId(experimentId);
    trackExperimentAction('Experiment Replicated');
    handleOpenReplicateDialog(experimentId);
  };

  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
  };

  const handleReplicateInputChange = (event) => {
    setReplicateFormData({
      ...replicateFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleReplicateSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/replicate/experiment/${currentExperimentId}`,
        replicateFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        setReplicateDialogOpen(false);
        fetchExperiments();
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleDelete = async (experimentId) => {
    try {
      confirmAlert({
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this experiment?',
        buttons: [
          {
            label: 'Yes',
            style: { backgroundColor: '#d32f2f', color: 'white' },
            onClick: async () => {
              try {
                const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/delete/${experimentId}`;
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
                const response = await axios.delete(apiUrl, config);

                if (response.status === 200) {
                  console.log('Experiment deleted successfully');
                  fetchExperiments();
                  toast.success('Experiment deleted successfully');
                } else {
                  console.error('Error deleting experiment:', response.data);
                  toast.error(`Error deleting experiment: ${response.data.message}`);
                }
                trackExperimentAction('Experiment Deleted');
              } catch (error) {
                console.error('Error deleting experiment:', error);
                toast.error(`Error deleting experiment: ${error.message}`);
              }
            },
          },
          {
            label: 'No',
            style: { backgroundColor: '#1976d2', color: 'white' },
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error('Error deleting experiment:', error);
      toast.error(`Error deleting experiment: ${error.message}`);
    }
  };

  const handleCloseDialog = () => {
    setInsertedQuestionIds([]);
    setShowConcepts(false)
    setAddDialogOpen(false);
    setCurrentExperimentId(null)
  };

  const handleSave = async () => {
    try {
      const requiredFields = [
        'experiment_name',
        'experiment_order',
        // 'question_type',
        'marks_per_question',
        'no_of_questions',
        'description',
        'instructions',
        'max_time',
        'coin_type',
        'clear_score',
        'concepts'
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          toast.error(`Please fill in all required fields.`);
          return;
        }
      }
      if (!isTimeValid(formData.max_time)) {
        toast.error('Invalid time format for Speed. Please use "hh:mm:ss".');
        return;
      }
      const apiUrl = formData.experiment_id
        ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/update/${formData.experiment_id}`
        : `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/add`;

      const payload = {
        experiment_name: formData.experiment_name,
        experiment_order: formData.experiment_order,
        lab_id: labId,
        question_type: formData.question_type||"lorem",
        marks_per_question: formData.marks_per_question,
        negative_marks: formData.negative_marks||0,
        no_of_questions: formData.no_of_questions,
        description: formData.description,
        instructions: formData.instructions,
        max_time: formData.max_time,
        isVisible: formData.isVisible,
        coin_type: formData.coin_type,
        questionIds: insertedQuestionIds,
        clear_score: formData.clear_score,
        concepts: formData.concepts
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios[formData.experiment_id ? "put" : "post"](
        apiUrl,
        payload,
        config
      );

      if (response.status === 201 || response.status === 200) {
        toast.success(`${response.data.message}`);
        fetchExperiments();
      } else {
        toast.error(`Error updating lab: ${response.data.message}`);
      }

      handleCloseDialog();
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isTimeValid = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  const handleToggle = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const handleDownload = async (token, experimentId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/warExercise/download/experiment/${experimentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'questions.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      trackEvent('Download Questions');
    } catch (error) {
      console.error('Error downloading questions:', error);
      toast.error('Error downloading questions');
    }
  };

  const onDownloadQuestions = (tempExperimentId) => {
    console.log("asdajshdkjas,",tempExperimentId)
    if (tempExperimentId && token) {
      handleDownload(token, tempExperimentId);
    } else {
      toast.error('No Experiment ID or token available');
    }
  };

  function formatTime(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);

    if (hours === 0) {
        if (minutes === 0) {
            return `${seconds} sec`;
        } else if (seconds === 0) {
            return `${minutes} mins`;
        } else {
            return `${minutes} mins ${seconds} sec`;
        }
    } else if (hours === 1) {
        if (minutes === 0) {
            if (seconds === 0) {
                return `${hours} hr`;
            } else {
                return `${hours} hr ${seconds} sec`;
            }
        } else if (seconds === 0) {
            return `${hours} hr ${minutes} mins`;
        } else {
            return `${hours} hr ${minutes} mins ${seconds} sec`;
        }
    } else {
        if (minutes === 0) {
            if (seconds === 0) {
                return `${hours} hrs`;
            } else {
                return `${hours} hrs ${seconds} sec`;
            }
        } else if (seconds === 0) {
            return `${hours} hrs ${minutes} mins`;
        } else {
            return `${hours} hrs ${minutes} mins ${seconds} sec`;
        }
    }
}


  return (
    <div className="container-fluid" style={{marginTop: '7vw'}}>
      <div className="align-center-all full-height">
        {isAuthorized ? (
          <Card className="col-md-12 p-3 shadow-none">
            <CardContent>
              <div className="top-div">
                <div style={{display: 'flex', gap: '1vw', alignItems: 'baseline'}}>
                <ArrowBackIosNewRounded onClick={handleBack} style={{cursor: 'pointer'}}/>
                <div className="page-header">Experiment</div>
                </div>
              <Button
                variant="contained"
                onClick={handleAdd}
                className="filled-button"
              >
                Add
              </Button>
              </div>
              <ToastContainer />
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>S. No.</th>
                    <th style={{textAlign: 'left'}}>Name</th>
                    <th>No of Questions</th>
                    <th>Time</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {experiments.map((experiment) => (
                    <tr key={experiment.experiment_id}>
                      <td>{experiment.experiment_id}</td>
                      <td style={{textAlign: 'left'}}>{experiment.experiment_name}</td>
                      <td>{experiment.no_of_questions}</td>
                      <td>{formatTime(experiment.max_time)}</td>
                      <td>
                        <Button
                          variant="contained"
                          onClick={() => handleEdit(experiment.experiment_id)}
                          className="mr-2 g-but"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            handleReplicate(experiment.experiment_id)
                          }
                          className="mr-2 y-but"
                        >
                          Replicate
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleDelete(experiment.experiment_id)}
                          className="mr-2 r-but"
                          >
                            Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardContent>
          </Card>
        ) : (
          <h2>You are unauthorized to access this page.</h2>
        )}

        <Dialog
          open={isAddDialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              width: "70vw",
              maxWidth: '100vw',
              padding: "1.5rem",
              borderRadius: "0.513vw",
              boxShadow:
                "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
            },
          }}
        >
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>{formData.experiment_id ? "Update Experiment" : "Add Experiment"}</div>
          <Button
            onClick={() => {
              setCurrentExperimentId(formData.experiment_id);
              onDownloadQuestions(formData.experiment_id);
            }}
            variant='contained'
            className="filled-button"
            >
            Download Questions
          </Button>
          </div>
          <DialogContent sx={{p: '0'}}>
            <Grid container spacing={0}>
            {inputFields.map((field) => (
              <Grid item xs={6} key={field.name}>
                {field.type === "toggle" ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.isVisible}
                        onChange={(e) => {
                          handleToggle(field.name, e.target.checked ? 1 : 0);
                        }}
                      />
                    }
                    label={field.label} 
                  />
                ) : (
                  <>
                  {field.name==='concepts' && (<>
                  <Button onClick={()=>{fetchListHandler(formData.experiment_id)}} className="filled-button" variant="contained">
                    Fetch Concepts
                  </Button>
                   {(showConcepts && (conceptList.length!==0)) && <p  style={{overflowWrap:"break-word", width:"100%",fontSize:"1rem",fontWeight:"400"}} >{conceptList}</p>}</>)}
                  <TextField
                    key={field.name}
                    label={field.label}
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    type={"text"}
                    disabled={field.disabled}
                    error={
                      field.name === "max_time" && !isTimeValid(formData.max_time)
                    }
                    helperText={
                      field.name === "max_time" && !isTimeValid(formData.max_time)
                        ? 'Invalid time format. Please use "hh:mm:ss".'
                        : ""
                    }
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  </>
                )}
              </Grid>
            ))}
            </Grid>
          </DialogContent>
          <ExperimentExercise
              onQuestionsAdded={(ids) => setInsertedQuestionIds(ids)}
              questionType={formData.question_type}
              experimentId={formData.experiment_id}
            />
          <DialogActions className="dialog-actions-box" sx={{paddingTop: '1.5vw'}}>
            <Button onClick={handleCloseDialog} variant="outlined" className="outlined-button" >
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" className="filled-button">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isReplicateDialogOpen}
          onClose={handleReplicateDialogClose}
          PaperProps={{
            sx: {
              padding: '1vw',
              width: '35.128vw',
            }
          }}
        >
          <DialogTitle sx={{p: 0}}>Replicate Experiment</DialogTitle>
          <DialogContent sx={{p: '0'}}>
            <FormControl
              fullWidth
              margin="normal"
              className="customTextfield"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
            >
              <InputLabel labelId="lab-select-label">Labs</InputLabel>
              <Select
                labelId="lab-select-label"
                // value={newSkillId}
                onChange={(e) =>
                  setReplicateFormData({
                    ...replicateFormData,
                    newLabId: e.target.value,
                  })
                }
                label="Labs"
              >
                {labs.map((lab) => (
                  <MenuItem key={lab.lab_id} value={lab.lab_id}>
                    {lab.lab_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="New Experiment Name"
              name="newExperimentName"
              value={replicateFormData.newExperimentName}
              onChange={(e) =>
                setReplicateFormData({
                  ...replicateFormData,
                  newExperimentName: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              type="text"
              className="customTextfield"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions className="dialog-actions-box">
            <Button onClick={handleReplicateDialogClose} className="outlined-button" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleReplicateSubmit} className="filled-button" variant="contained">
              Replicate
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Experiment;
