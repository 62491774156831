import React from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const GreenTickDialog = ({handleClose, openSuccessMessage, massage1, massage2, subject}) => {
  return (
    <>
      {/* on Successfull Update/Add Dialog Box */}
      <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openSuccessMessage}
        >
          <div style={{ marginBottom: '2rem' }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ width: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '3rem', paddingTop: '0' }}>
            <CheckCircleIcon style={{ color: 'green', fontSize: '2rem', margin: '1rem' }} />
            <h4 style={{textAlign: 'center'}}>{massage1} Successful!</h4>
            <p style={{ textAlign: 'center', fontSize: '0.75rem' }}>{subject} has been {massage2} successfully.</p>
          </div>
        </BootstrapDialog>
    </>
  )
}

export default GreenTickDialog