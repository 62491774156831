import React, { lazy, useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./BulkLogin.module.css"
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  TextField,
  InputLabel, Select, Checkbox, ListItemText, MenuItem, OutlinedInput, Autocomplete
} from "@mui/material";
import { readFile } from "../utils/utils";
import { useLocalStorage } from "@uidotdev/usehooks";
import isDesktop from "../isDesktop";
import BulkLoginMobile from "./BulkLoginMobile";

const BulkLogin = () => {
  const isDesktopDevice = isDesktop();
  const [token, setToken] = useLocalStorage('token', null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [updateFile, setUpdateFile] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [selectedInstitute, setSelectedInstitute] = useState(null);

  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(14);

  const handleAdd = async () => {
    try {
      const formData = new FormData();
      formData.append("excelFile", selectedFile);

      await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Users added successfully");
    } catch (error) {
      console.error("Error adding Users:", error);
      if (error.response && error.response.status === 409) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(`Error adding Users: ${error.message}`);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file.name);
  };
  const [updateFileName, setUpdateFileName] = useState("");
  const handleUpdateFileChange = (event) => {
    const file = event.target.files[0];
    setUpdateFile(file);
    setUpdateFileName(file.name);
  };

  const fromDateChangeHandler = (value) => {
    const date = value.target.value;
    setFromDate(date);
  };

  const toDateChangeHandler = (value) => {
    const date = value.target.value;
    setToDate(date);
  };
  const getInstituteData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/institute/getAll`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data.data;
    const temp = response.data.data.map((ele) => {
      return {
        label: `${ele.institute_name} (${ele.institute_referral_id})`,
        institute_id: ele.institute_id,
      };
    });
    console.log(temp, " got all instit");
    setInstitutes(temp);
  };
  useEffect(() => {
    isDesktopDevice && getInstituteData();
  }, []);

  const onDownloadPress = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/getAll`,
        {
          responseType: "blob",
          params: {
            selectedInstitute: encodeURIComponent(
              JSON.stringify(selectedInstitute)
            ),
            fromDate: encodeURIComponent(JSON.stringify(fromDate)),
            toDate: encodeURIComponent(JSON.stringify(toDate)),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BulkUsers.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success("File downloaded successfully");
    } catch (error) {
      const { data } = error.response;
      const file = await readFile(data);
      const { message } = JSON.parse(file);
      console.error(error);
      
      toast.error(message);
    }
  };

  const onDisplayPress = async () => {
    if(!selectedInstitute || !fromDate || !toDate) {
      toast.error("Please select all fields")
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/display`,
        {
          params: {
            selectedInstitute: encodeURIComponent(
              JSON.stringify(selectedInstitute)
            ),
            fromDate: encodeURIComponent(JSON.stringify(fromDate)),
            toDate: encodeURIComponent(JSON.stringify(toDate)),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTableData(response.data.data)
      console.log(response.data.data, " all data for display");
      toast.success("Data fetched successfully");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message)
    }
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("excelFile", updateFile);

      await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/bulkRegister/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Users added successfully");
    } catch (error) {
      console.error("Error adding Users:", error);
      if (error.response && error.response.status === 409) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(`Error adding Users: ${error.message}`);
      }
    }
  };

  if (!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page</h2>
      </div>
    );
  }
  /* Date & Time Formatter */
  function formatDateAndTime(date) {
    date = new Date(date);
    let ISTDate = new Date(date.getTime());

    let day = ISTDate.getDate();
    let month = ISTDate.getMonth() + 1;
    let year = ISTDate.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    let formattedDate = `${day}.${month}.${year}`;

    let hours = ISTDate.getHours();
    let minutes = ISTDate.getMinutes();
    let AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let formattedTime = `${hours}:${minutes} ${AmOrPm}`;

    return { time: formattedTime, date: formattedDate };
  }
  return (
    <>
    {
      isDesktopDevice ? (
      <>
      <div style={{ paddingTop: '4rem' }}>
        <Card elevation={3} className="col-md-12 p-3 d-flex mt-3 mb-3" style={{ boxShadow: 'none' }}>
          <CardContent className="d-flex flex-column w-100">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2>Bulk Login</h2>
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    setUpdateDialog(true);
                  }}
                  className="button-two mr-1"
                  style={{ width: '15.385vw', background: '#37446F', height: '2.564vw' }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setAddDialog(true);
                  }}
                  className="button-two"
                  style={{ width: '15.385vw', background: '#37446F', height: '2.564vw' }}
                >
                  Add
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2.179vw', marginRight: '10.1vw' }}>
              <FormControl className='mb-3' sx={{
                width: '100%', '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                  },
                },
                width: '32.051vw',
                marginTop: '22px',
                marginRight: '20px',
              }}
                style={{
                  boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                }}
              >
                {/* <InputLabel id="demo-multiple-checkbox-label" style={{ color: "#6c6c6c" }}>Institute Name</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={selectedInstitute}
                  onChange={handleFormChange}
                >
                  {institutes.map((roles) => (
                    <MenuItem key={roles.label} value={roles.label}>
                      <ListItemText primary={roles.label} />
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                    value={selectedInstitute}
                    onChange={(event, newValue) => {
                      setSelectedInstitute(newValue);
                    }}
                    options={institutes}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Institute Name" />
                    )}
                />
              </FormControl>
              <TextField
                label="From Date"
                type="date"
                value={fromDate}
                onChange={fromDateChangeHandler}
                InputProps={{ style: { height: 'auto' } }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                style={{ marginRight: "1vw" }}
              />
              <TextField
                label="To Date"
                type="date"
                value={toDate}
                onChange={toDateChangeHandler}
                InputProps={{ style: { height: 'auto' } }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
            </div>
            <div className="row mt-3" style={{ margin: 'auto' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onDownloadPress}
                style={{ alignSelf: "center", width: '15.385vw', background: '#37446F', height: '2.564vw' }}
                className="button-two mr-3"
              >
                Download
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onDisplayPress}
                style={{ alignSelf: "center", width: '15.385vw', background: '#37446F', height: '2.564vw' }}
                className="button-two"
              >
                Display
              </Button>
            </div>
            {
              tableData.length > 0 && (
            <table className={`table  ${styles.table}`}>
              <thead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                <tr>
                  <th className={styles.tableCell}>S. No.</th>
                  <th className={styles.tableCell} style={{ textAlign: 'left' }}>Name & Username</th>
                  <th className={styles.tableCell}>Date</th>
                  <th className={styles.tableCell} style={{ textAlign: 'left' }}>Email</th>
                  <th className={styles.tableCell}>Mobile</th>
                  <th className={styles.tableCell}>DOB</th>
                  <th className={styles.tableCell}>ReferredBy</th>
                  <th className={styles.tableCell}>Subscriptions</th>
                </tr>
              </thead>
              <tbody>
                {
                  tableData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.tableCell} >{data.userId}</td>
                        <td className={styles.tableCell} style={{ textAlign: 'left' }}>
                          <>
                            <p style={{ padding: '0', margin: '0' }}>{data.name}</p>
                            <p style={{ padding: '0', margin: '0' }}>{data.username}</p>
                          </>
                        </td>
                        <td className={styles.tableCell} >
                            <p style={{ padding: '0', margin: '0' }}>{formatDateAndTime(data.createdAt).date}</p>
                            <p style={{ padding: '0', margin: '0' }}>{formatDateAndTime(data.createdAt).time}</p>
                        </td>
                        <td className={styles.tableCell} style={{ textAlign: 'left' }}>{data.email}</td>
                        <td className={styles.tableCell} >{data.mobile}</td>
                        <td className={styles.tableCell} >{data.dob}</td>
                        <td className={styles.tableCell} >{data.referredBy}</td>
                        <td className={styles.tableCell} >{data.subscriptions}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
              )
            }
          </CardContent>
        </Card>
      </div>

      {/* Add Dialog */}
      <Dialog
        open={addDialog}
        fullWidth
        onClose={() => {
          setAddDialog(false);
          setAddDialog(false);
          setSelectedFileName("");
          setSelectedFile(null);
        }}
        className="d-flex flex-column justify-content-center ml-3"
      >
        <DialogTitle style={{fontWeight: 'bold'}}>Add User</DialogTitle>
        <DialogContent>
          <label htmlFor="file-upload" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: '#838181', width: '100%', height: '3.056vw', boxShadow: '0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset', cursor: 'pointer'}} >
            Choose File
          </label>
          <Input
            id="file-upload"
            type="file"
            style={{ display: 'none', }}
            fullWidth
            onChange={handleFileChange}
          />
           <p style={{ margin: '0', padding: '0', textAlign: 'center' }}>{selectedFileName ? 'File Chosen' : 'No file chosen'}</p>
          <ToastContainer />
          <Button
            variant="contained"
            onClick={handleAdd}
            fullWidth
            style={{ marginTop: "1vw", background: '#37446F', height: '2.564vw', textTransform: 'none' }}
          >
            Add Users
          </Button>
        </DialogContent>
      </Dialog>
      
      {/* Update Dialog */}
      <Dialog
        open={updateDialog}
        fullWidth
        onClose={() => {
          setUpdateDialog(false);
          setUpdateDialog(false);
          setUpdateFileName("");
          setUpdateFile(null);
        }}
        className="d-flex flex-column justify-content-center ml-3"
      >
        <DialogTitle style={{fontWeight: 'bold'}}>Update User</DialogTitle>
        <DialogContent>
          <label htmlFor="file-upload" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: '#838181', width: '100%', height: '3.056vw', boxShadow: '0px 2px 4px 0px #0000001A, 0px 2px 4px 0px #0000001A inset', cursor: 'pointer'}} >
            Choose File
          </label>
          <Input
            id="file-upload"
            type="file"
            fullWidth
            onChange={handleUpdateFileChange}
            style={{ display: 'none', }}
          />
           <p style={{ margin: '0', padding: '0', textAlign: 'center' }}>{updateFileName ? 'File Chosen' : 'No file chosen'}</p>
          <ToastContainer />
          <Button
            variant="contained"
            onClick={handleUpdate}
            fullWidth
            style={{ marginTop: "1vw", background: '#37446F', height: '2.564vw', textTransform: 'none' }}
          >
            Update Users
          </Button>
        </DialogContent>
      </Dialog>
      </>
      ) : (
        <BulkLoginMobile />
      )
    }
    </>
  );
};

export default BulkLogin;
