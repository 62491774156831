import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Signup from '../auth/signup/Signup';
import axios from 'axios';
import { FormControl, TextField, Checkbox, InputLabel, Select, OutlinedInput, ListItemText, IconButton, InputAdornment, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import roleService from '../roles/roleService';
import { MenuItem } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import isDesktop from '../isDesktop';
import ManageEmployeeMobile from './ManageEmployeeMobile';

const ManageEmployee = () => {
  const isDesktopDevice = isDesktop();
  const [employees, setEmployees] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([])
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(7);
  const [resetDialog, setResetDialog] = useState(false);
  const [userConfirmation, setUserConfirmation] = React.useState(false);

  const [formValues, setFormValues] = useState({
    id: '',
    name: '',
    role: '',
    email: '',
    phone: '',
    dob: '',
  });

  const formatDate = (utcDateString) => {
    const dobUTC = utcDateString;
    const dobDate = new Date(dobUTC);
    return dobDate;
  };

  const [resetPassword, SetResetPassword] = useState({
    id: "",
    password: "",
    confirmPassword: "",
  });

  const handleUpdate = (employeeId) => {
    const selected = employees.find((employee) => employee.emp_id === employeeId);
    console.log(new Date(formatDate(selected.dob)));
    console.log(formatDate(selected.dob));
    setSelectedEmployee(selected);
    setFormValues({
      id: selected.emp_id,
      name: selected.emp_name,
      role: selected.role,
      email: selected.email,
      phone: selected.phone,
      dob: formatDate(selected.dob),
    });
    setUpdateDialog(true);
  };

  const handleResetPassword = (employeeId) => {
    const selected = employees.find((employee) => employee.emp_id === employeeId);

    setSelectedEmployee(selected);
    SetResetPassword({
      id: employeeId
    })
    setResetDialog(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialog(false);
  };

  const handleDateChange = (value) => {
    const formattedDate = dayjs(value).format('DD/MM/YYYY');
    console.log(formattedDate);
    
    setFormValues((prevValues) => ({ ...prevValues, dob: formattedDate }));
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, " hello there");
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleUpdateEmployee = async () => {
    const { id, name, role, email, phone, dob } = formValues;

    if (!id || !role || !name || !email || !phone || !dob) {
      toast.error('All fields are required');
      console.error('All fields are required');
      return;
    }
    const formattedDate = dayjs(dob).format('DD/MM/YYYY');
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/update/${id}`,
        {
          name,
          role,
          email,
          phone,
          dob:formattedDate
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Employee updated successfully');
        fetchEmployees();
        setUpdateDialog(false);
      } else {
        toast.error('Error updating employee: ', response.data.message);
      }
    } catch (error) {
      toast.error('Error updating employee: ', error);
    }
  };


  const handleResetButtonClick = async () => {
    const { id, password } = resetPassword;

    if (!password || !id) {
      toast.error('All fields are required');
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/reset/${id}`,
        {
          password
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchEmployees();
        setResetDialog(false);
        toast.success("Password reset successfully")
      } else {
        toast.error('Error updating employee: ', response.data.message);
      }
    } catch (error) {
      toast.error('Error updating employee: ', error);
    }
  };


  const [token, setToken] = useLocalStorage('token', null);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/all`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setEmployees(response.data.data);
      } else {
        console.error('Error fetching employees: ', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching employees: ', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const activeRoles = await roleService.getRoles(token);
      setRoles(activeRoles);
    } catch (error) {
      console.error("Error fetching roles:", error.response);
    }
  };

  useEffect(() => {
    if(isDesktopDevice){
      fetchEmployees();
      fetchRoles();
    }
  }, [token]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setFormValues({
      name: '',
      role: '',
      email: '',
      phone: '',
      dob: '',
      password: '',
      confirmPassword: '',
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setResetDialog(false);
    SetResetPassword({
      password: "",
      confirmPassword: "",
    })
    fetchEmployees();
  };

  const handleAddNewEmployee = (newEmployee) => {
    console.log(formValues, " new employee");
    const { name, role, email, phone, dob, password } = formValues;
    if(!name || !role || !email || !phone || !dob || !password || !formValues.confirmPassword) {
      toast.error('All fields are required');
      return;
    }else if(password !== formValues.confirmPassword){
      toast.error('Passwords do not match');
      return;
    }
    setEmployees((prevEmployees) => [...prevEmployees, newEmployee]);
    handleCloseDialog();
    setFormValues({});
  };

  const [open, setOpen] = React.useState(false);
  const handleDelete = async (employeeId) => {
    setOpen(true);
    const selected = employees.find((employee) => employee.emp_id === employeeId);
    setSelectedEmployee(selected);
  };
  const handleConfirmDelete = async () => {
    setOpen(false);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/auth/employees/delete/${selectedEmployee.emp_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Employee deleted successfully');
        fetchEmployees();
      } else {
        toast.error('Error deleting employee: ', response.data.message);
      }
    } catch (error) {
      toast.error('Error deleting employee: ', error);
    }
  }

  const handleResetFormChange = (e) => {
    const { name, value } = e.target;
    SetResetPassword((prevValues) => ({ ...prevValues, [name]: value }));
  };
  
  return (
    <>
      {
        isAuthorized ? (
          isDesktopDevice ? (
            <div className="container-fluid" style={{ paddingTop: '4rem' }}>
              <div className="align-center-all full-height">
                <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                  <CardContent>
                    <ToastContainer />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                      <h2>Employee</h2>
                      <Button variant="contained" color="primary" onClick={handleOpenDialog} className="button-two" style={{ width: '15.385vw', background: '#37446F', height: '2.564vw' }}>
                        Add
                      </Button>
                    </div>
                    <table className="table">
                      <thead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                        <tr>
                          <th style={{ width: '5.128vw' }}>S. No.</th>
                          <th style={{ textAlign: 'left' }}>Name</th>
                          <th style={{ width: '48.462vw' }}>Role</th>
                          <th style={{ width: '416px', textAlign: 'left' }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employees.map((employee) => (
                          <tr key={employee.emp_id}>
                            <td>{employee.emp_id}</td>
                            <td style={{ textAlign: 'left' }}>{employee.emp_name}</td>
                            <td>{employee.role}</td>
                            <td>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleUpdate(employee.emp_id)}
                                className="mr-2"
                                style={{ background: '#CBEBF6', color: 'black', boxShadow: '2px 2px 4px 0px #AEAEC080', textTransform: 'none', width: '112px', height: '32px' }}
                              >
                                Update
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => handleResetPassword(employee.emp_id)}
                                className="mr-2"
                                style={{ background: '#FCF6BC', color: 'black', boxShadow: '2px 2px 4px 0px #AEAEC080', textTransform: 'none', width: '150px', height: '32px' }}
                              >
                                Reset Password
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => handleDelete(employee.emp_id)}
                                style={{ background: '#FFD6D2', color: 'black', boxShadow: '2px 2px 4px 0px #AEAEC080', textTransform: 'none', width: '112px', height: '32px' }}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <Dialog open={openDialog} onClose={handleCloseDialog} style={{ margin: 'auto', overflow: 'hidden' }}>
                      <DialogTitle style={{ fontWeight: '600', fontSize: '18px', textAlign: 'left' }}>Add New Employee</DialogTitle>
                      {/* <p style={{ marginTop: '-13px', textAlign: 'center', fontWeight: '600', padding: '0', margin: '0' }}>Admin Signup</p> */}
                      <Signup onAddEmployee={handleAddNewEmployee} handleCloseDialog={handleCloseDialog} />
                    </Dialog>
                    <Dialog open={updateDialog} onClose={handleUpdateDialogClose}>
                      <DialogTitle style={{ fontWeight: '500' }}>Update Employee</DialogTitle>
                      <form className="m-2" style={{ padding: '0px 14px 14px 14px' }}>
                        <TextField
                          className="mb-3"
                          type="text"
                          label="Employee Name"
                          name="name"
                          variant="outlined"
                          value={formValues.name}
                          onChange={handleFormChange}
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.MuiInputBase-root fieldset": {
                                borderColor: "white",
                              },
                            },
                          }}
                          InputProps={{
                            style: {
                              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                            },
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                        />
                        <TextField
                          className="mb-3"
                          type="email"
                          label="Email"
                          name="email"
                          variant="outlined"
                          value={formValues.email}
                          onChange={handleFormChange}
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.MuiInputBase-root fieldset": {
                                borderColor: "white",
                              },
                            },
                          }}
                          InputProps={{
                            style: {
                              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                            },
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                        />
                        <br />
                        <FormControl className='mb-3' sx={{
                          width: '100%', '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: 'none',
                            },
                          },
                        }}
                          style={{
                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                          }}
                        >
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: "#A8A8A8" }}>Role</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name="role"
                            value={formValues.role}
                            onChange={handleFormChange}
                            label="Role"
                          // input={<OutlinedInput label="Role" />}
                          // renderValue={(selected) => selected.join(', ')}
                          // MenuProps={MenuProps}
                          >
                            {roles.map((roles) => (
                              <MenuItem key={roles.role} value={roles.role}>
                                <ListItemText primary={roles.role} />
                                {/* <Checkbox checked={personName.indexOf(roles.role) > -1} /> */}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <br />
                        <TextField
                          className="mb-3"
                          type="text"
                          label="Mobile"
                          name="phone"
                          variant="outlined"
                          value={formValues.phone}
                          onChange={handleFormChange}
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.MuiInputBase-root fieldset": {
                                borderColor: "white",
                              },
                            },
                          }}
                          InputProps={{
                            style: {
                              boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                            },
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                        />
                        <FormControl fullWidth className='mb-3' sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: 'none',
                            },
                          },
                          "&.Mui-focused": { color: "#A8A8A8" }
                        }} style={{
                          boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                        }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="YYYY-MM-DD"
                              value={dayjs(formValues.dob)}
                              label="Date of Birth"
                              onChange={handleDateChange}
                              renderInput={(params) => <TextField {...params} error={false} />}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <br />
                        <Button
                          variant="contained"
                          onClick={() => setUpdateDialog(false)}
                          className="mt-2"
                          sx={{ color: 'black' }}
                          style={{ width: '15.385vw', background: 'none', border: '1px solid #37446F', height: '2.564vw', marginRight: '20px', textTransform: 'none' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdateEmployee}
                          className="mt-2 button-two"
                          style={{ width: '15.385vw', background: '#37446F', height: '2.564vw' }}
                        >
                          Update
                        </Button>
                      </form>
                    </Dialog>

                    {/* Reset Employee Password */}
                    <Dialog open={resetDialog} onClose={handleCloseDialog}>
                      <DialogTitle style={{ fontWeight: '500' }}>Reset Employee Password</DialogTitle>
                      <form className="m-2">
                        <FormControl variant="outlined" className='mb-3' style={{ width: '100%' }} sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: 'none',
                            },
                          },
                          boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                        }}>

                          <InputLabel htmlFor="outlined-adornment-password" sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}>
                            Password
                          </InputLabel>
                          <OutlinedInput
                            fullWidth={true}
                            name="password"
                            onChange={handleResetFormChange}
                            sx={{ backgroundColor: "transparent" }}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  style={{ background: "none" }}
                                  onClick={() => {
                                    setShowPassword((prev) => !prev);
                                  }}
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                        
                        <FormControl variant="outlined" className='mb-3' style={{ width: '100%' }} sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                              boxShadow: 'none',
                            },
                          },
                          boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                        }}>
                          <InputLabel htmlFor="outlined-adornment-password" sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}>
                            Confirm Password
                          </InputLabel>
                          <OutlinedInput
                            fullWidth={true}
                            name="confirmPassword"
                            onChange={handleResetFormChange}
                            sx={{ backgroundColor: "transparent" }}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  style={{ background: "none" }}
                                  onClick={() => {
                                    setShowPassword((prev) => !prev);
                                  }}
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Confirm Password"
                          />
                        </FormControl>
                        <br />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setResetDialog(false)}
                          className="mt-2"
                          disabled={!resetPassword.confirmPassword || resetPassword.confirmPassword !== resetPassword.password}
                          style={{ width: '15.385vw', height: '2.564vw', background: 'none', border: '2.03px solid #37446F', color: 'black', marginRight: '8px', textTransform: 'none' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleResetButtonClick}
                          className="mt-2"
                          disabled={!resetPassword.confirmPassword || resetPassword.confirmPassword !== resetPassword.password}
                          style={{ width: '15.385vw', background: '#37446F', height: '2.564vw', color: 'white', textTransform: 'none' }}
                        >
                          Reset password
                        </Button>
                      </form>
                    </Dialog>
                  </CardContent>
                </Card>

                {/* Delete Dialog Box */}
                <Dialog
                  open={open}
                  onClose={(prev) => !prev}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  style={{ textAlign: 'center' }}
                >
                  <ErrorOutlineIcon style={{ color: 'red', fontSize: '32px', margin: '12px auto' }} />
                  <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px', fontWeight: '500', lineHeight: '0' }}>
                    {"Are you Sure?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ fontSize: '12px' }}>
                      You won't be able to revert this!
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)} style={{ textTransform: 'none', width: '120px', height: '36px', border: '2.03px solid #37446F', borderRadius: '5.85px', background: 'none', color: 'black' }}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} style={{ textTransform: 'none', width: '120px', height: '36px', borderRadius: '5.85px', background: '#37446F', color: 'white' }} autoFocus>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          ) : (
            <ManageEmployeeMobile />
          )
        ) : (
          <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page.</h2>
        )
      }
    </>
  );
};

export default ManageEmployee;
