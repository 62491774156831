import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Select,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import roleService from "../../roles/roleService";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { signUp } from "../authService";
import { TextField } from "@mui/material";
import "./Signup.css";
import { ToastContainer, toast } from "react-toastify";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Signup = ({ handleCloseDialog }) => {
  const [roles, setRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [formData, setFormData] = useState({
    role: "",
    name: "",
    email: "",
    phone: "",
    dob: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({ ...formData, [name]: value });
  };
  const handleDateChange = (value) => {
    console.log(dayjs(value).format("DD-MM-YYYY"), " handleDateChange"); // '16-12-2001'
    const formattedDate = dayjs(value).format("DD/MM/YYYY");
    setFormData((prevValues) => ({ ...prevValues, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { success, data, error } = await signUp(formData);

      if (success) {
        toast.success("Signup successful:", data);
        setFormData({
          role: "",
          name: "",
          email: "",
          phone: "",
          dob: "",
          password: "",
          confirmPassword: "",
        });
        handleCloseDialog();
      } else {
        toast.error("Signup failed:", error);
      }
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  const [token, setToken] = useLocalStorage("token", null);

  const fetchRoles = async () => {
    try {
      const activeRoles = await roleService.getRoles(token);
      setRoles(activeRoles);
    } catch (error) {
      console.error("Error fetching roles:", error.response.data);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <div className="container-fluid">
      <div className="align-center-all full-height">
        <div className="signup-form mb-4">
          <p
            style={{
              marginTop: "-0.813rem",
              textAlign: "center",
              fontWeight: "600",
              padding: "0",
              margin: "0",
            }}
          >
            Admin Signup
          </p>
          <form onSubmit={handleSubmit}>
            {/* <FormControl fullWidth className="input">
                  <InputLabel htmlFor="name">Employee Name</InputLabel>
                  <Input
                    id="name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormControl> */}
            <TextField
              className="mb-3 mt-3"
              type="text"
              label="Employee Name"
              variant="outlined"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
            />

            {/* <FormControl fullWidth className="input">
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </FormControl> */}
            <TextField
              className="mb-3"
              type="text"
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
            />

            <FormControl
              fullWidth
              className="mb-3"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              style={{
                boxShadow:
                  "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ color: "#6c6c6c" }}
              >
                Role
              </InputLabel>
              <Select
                // className="m-1"
                // type="text"
                // label="Role"
                name="role"
                // variant="standard"
                value={formData.role}
                onChange={handleInputChange}
              >
                {roles?.map((roles) => (
                  <MenuItem key={roles.role} value={roles.role}>
                    {roles.role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <FormControl fullWidth className="input">
                  <InputLabel htmlFor="phone">Phone Number</InputLabel>
                  <Input
                    id="phone"
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </FormControl> */}
            <TextField
              className="mb-3"
              type="tel"
              label="Mobile"
              variant="outlined"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
            />

            <FormControl
              fullWidth
              className="mb-3"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
                "&.Mui-focused": { color: "#A8A8A8" },
              }}
              style={{
                boxShadow:
                  "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
              }}
            >
              {/* <InputLabel htmlFor="dob">Date of Birth</InputLabel>
                  <Input
                    id="dob"
                    type="text"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                  /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="YYYY-MM-DD"
                  value={dayjs(formData.dob)}
                  label="Date of Birth"
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField {...params} error={false} />
                  )}
                />
              </LocalizationProvider>
            </FormControl>

            {/* <FormControl fullWidth className="input">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </FormControl> */}
            {/* <TextField
              className="mb-3"
              type="password"
              label="Password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
            /> */}
            <FormControl
              variant="outlined"
              className="mb-3"
              style={{ width: "100%" }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
                boxShadow:
                  "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
              }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}
              >
                Password
              </InputLabel>
              <OutlinedInput
                fullWidth={true}
                name="password"
                onChange={handleInputChange}
                sx={{ backgroundColor: "transparent" }}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      style={{ background: "none" }}
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className="mb-3"
              style={{ width: "100%" }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
                boxShadow:
                  "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
              }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ "&.Mui-focused": { color: "#A8A8A8" } }}
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                fullWidth={true}
                name="confirmPassword"
                onChange={handleInputChange}
                sx={{ backgroundColor: "transparent" }}
                id="outlined-adornment-password"
                type={showCPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      style={{ background: "none" }}
                      onClick={() => {
                        setShowCPassword((prev) => !prev);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showCPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            {/* <FormControl fullWidth className="input">
                  <InputLabel htmlFor="confirmPassword">
                    Confirm Password
                  </InputLabel>
                  <Input
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                </FormControl> */}
            {/* <TextField
              className="mb-3"
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    boxShadow: "none",
                  },
                },
              }}
              InputProps={{
                style: {
                  boxShadow:
                    "0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A",
                },
              }}
              InputLabelProps={{
                sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                required: false,
              }}
            /> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
                marginTop: "0.75rem",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "#37446F",
                  color: "white",
                  flex: "1",
                  textTransform: "none",
                }}
                disabled={
                  !formData.name ||
                  !formData.email ||
                  !formData.phone ||
                  !formData.dob ||
                  !formData.password ||
                  formData.password !== formData.confirmPassword
                }
              >
                Sign Up
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "black",
                  background: "none",
                  border: "2px solid #37446F",
                  flex: "1",
                  textTransform: "none",
                }}
                onClick={handleCloseDialog}
              >
                Close
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
