import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Card, ListItemText, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CardContent from "@mui/material/CardContent";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';
import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import Sidebar from "../sidenav/Sidenav";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  IconButton,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from "axios";
import Report from "./Report";
import { useLocalStorage } from "@uidotdev/usehooks";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ExperimentReportMobile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const [token, setToken] = useLocalStorage('token', null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [experiment, setExperiment] = useState([]);
  const [labs, setLabs] = useState([]);
  const [isDropDownClicked, setIsDropDownClicked] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedLab, setSelectedLab] = useState([]);
  const [currentExperiments, setCurrentExperiments] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs("01/01/2024"));
  const [toDate, setToDate] = useState(dayjs());
  const [reports, setReports] = useState([]);
  const [selectedExperiment, setSelectedExperiment] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [attemptId, setAttemptId] = useState();
  const [userId, setUserId] = useState();
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(9);

  const fetchLabs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status == 200) {
        const temp = response?.data?.data?.map((val) => {
          return {
            label: val?.lab_name,
            id: val?.lab_id,
          };
        });
        // console.log(typeof temp);

        setLabs(temp);
      }
    } catch (error) {
      toast.error("Error Fetching Labs");
      console.error(error);
    }
  };
  const fetchExperiments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/experiment/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setExperiment(response.data.data);
      } else {
        console.error("Error fetching experiments:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching experiments:", error);
    }
  };

  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/experiment-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReports(response.data.data);
      } else {
        console.error("Error fetching reports:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleExperimentChange = (e) => {
    setSelectedExperiment(
      e.target.value
    );
  };

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const handleReset = () => {
    setSelectedLab("");
    setFilter("");
    setSelectedExperiment("");
  };

  useEffect(()=>{
    fetchLabs();
  },[])

  useEffect(() => {
    // fetchReports();
    fetchExperiments();
  }, [selectedExperiment]);

  const handleExportClick = async () => {
    console.log(filter, selectedLab, selectedExperiment, fromDate, toDate, " Exporting file");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/export-experiment`,
        {
          name: filter,
          lab_id:selectedLab.id,
          experiment_id: selectedExperiment.id,
          from:fromDate.toDate()||null,
          to:toDate||null
        },
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "experimentAttempts.xlsx");
      document.body.appendChild(link);
      link.click();

      console.log("File exported successfully");
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  const handleSingleExport = (attempt_id, user_id) => {
    setShowReport(true);
    setAttemptId(attempt_id);
    setUserId(user_id);
  };

  const labChangeHandler = (e, value) => {
    console.log(value);

    setExperiment(value);
    const temp = experiment
      ?.filter((val) => (val.lab_id === value?.id))
      ?.map((val) => {
        return {
          label: val?.experiment_name,
          id: val?.experiment_id,
        };
      });
    setSelectedLab(value)
    setSelectedExperiment([]);
    setCurrentExperiments(temp);
  };

  const experimentChangeHandler = (e, value) => {
    setSelectedExperiment(value);
  };

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    return `${formattedDate} ${formattedTime}`;
  }

  /* MUI Table */
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" scope="row">
            <div>{row.name}</div>
            <div>{row.user_class}</div>
          </TableCell>
          <TableCell align="right">
            <div>{formatDate(row.start_time)}</div>
            <div>{formatDate(row.end_time)}</div>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& td": { border: 0, height: open ? 'auto' : 0 }, borderBottom: '1px solid black' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit  >
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 150px', rowGap: '6px', fontSize: '10px', marginTop: '4px', marginBottom: '4px' }}>
                <div>Total Score: {row.total_questions}</div>
                <div>Earned Score: {row.total_correct}</div>
                {/* <div>Message: {row.message}</div> */}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to={'/profile'}>
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <h4 style={{ margin: '1.313rem 0.938rem' }}>Experiment Report</h4>
      <div className="container-fluid" style={{ marginTop: '2.25rem' }}>
        <div className="align-center-all full-height">
          <Card style={{ boxShadow: 'none' }}>
            <CardContent style={{ padding: '0' }}>
              <div className="filters">
                <div className="row">
                  <div className="col-md-3">
                    <Autocomplete
                        className="mb-3"
                        style={{ marginTop: '0.313rem' }}
                        value={selectedLab}
                        fullWidth
                        options={labs}
                        // getOptionLabel={option=>option.label}
                        // getOptionLabel={(option)=>option.label}  
                        onChange={(e, value) => {
                          labChangeHandler(e, value);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id || value === null
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} label="Labs" sx={{
                            width: '100%', '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                width: '100%',
                                boxShadow: 'none',
                              },
                            },
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                          style={{
                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                          }} />
                        )}
                    />
                    {/* <FormControl className="mt-3" fullWidth sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    >
                      <InputLabel id="experiment-label" style={{ color: "#6c6c6c" }}>
                        Experiment
                      </InputLabel>
                      <Select
                        labelId="experiment-label"
                        id="experiment-select"
                        value={selectedExperiment}
                        onChange={handleExperimentChange}
                      >
                        {experiment.map((experimentItem) => (
                          <MenuItem key={experimentItem.experiment_id} value={experimentItem.experiment_id}>
                            <ListItemText primary={experimentItem.experiment_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <Autocomplete
                        className="mb-3"
                        value={selectedExperiment}
                        fullWidth
                        options={currentExperiments}
                        onChange={(e, value) => {
                          experimentChangeHandler(e, value);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id || value === null
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} label="Experiments" sx={{
                            width: '100%', '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                width: '100%',
                                boxShadow: 'none',
                              },
                            },
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                          style={{
                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                          }} />
                        )}
                    />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1.538vw",
                        }}
                      >
                        <FormControl
                          className="mb-3 dateField"
                          sx={{
                            width: '100%', '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                width: '100%',
                                boxShadow: 'none',
                              },
                            },
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                          style={{
                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="From"
                              value={fromDate}
                              onChange={(value) => {
                                setFromDate(dayjs(value).endOf("day"));
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl
                          fullWidth
                          className="mb-3 dateField"
                          sx={{
                            width: '100%', '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'transparent',
                              },
                              '&:hover fieldset': {
                                borderColor: 'transparent',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'transparent',
                                width: '100%',
                                boxShadow: 'none',
                              },
                            },
                          }}
                          InputLabelProps={{
                            sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                            required: false
                          }}
                          style={{
                            boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="To"
                              value={toDate}
                              onChange={(value) => {
                                setToDate(dayjs(value).endOf("day"));
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </div>

                    <TextField
                      className="mt-3"
                      label="Name"
                      variant="outlined"
                      value={filter}
                      fullWidth
                      onChange={handleInputChange}
                      placeholder="Enter Name"
                      sx={{
                        width: '100%', '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            width: '100%',
                            boxShadow: 'none',
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                        required: false
                      }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                    />

                    <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Button
                        fullWidth
                        color="error"
                        style={{ flex: 1 }}
                        variant="contained"
                        className="mr-1 button-two cancelMobileButtonStyles"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                      <Button
                        fullWidth
                        style={{ flex: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={handleExportClick}
                        className="button-two addMobileButtonSytles"
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              {
                reports.length > 0 &&
                (
                  <Table aria-label="collapsible table" sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none"
                    },
                    marginTop: '1.25rem',
                  }}>
                    <TableHead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                      <TableRow>
                        <TableCell>Name & Class</TableCell>
                        <TableCell align="right">Date & Time</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports
                        ?.filter(
                          (report) =>
                            report.name
                              ?.toLowerCase()
                              .includes(filter?.toLowerCase()) &&
                            report.experiment_id
                              ?.toString()
                              .includes(selectedExperiment)
                        )
                        ?.map((filteredReport) => (
                          <Row key={filteredReport.attempt_id} row={filteredReport} />
                        ))}
                    </TableBody>
                  </Table>
                )
              }
            </CardContent>
          </Card>
        </div>
      </div>
      <Link to={'/report'}>
        <ArrowCircleLeftIcon sx={{ fill: '#37446f' }} style={{ position: 'fixed', bottom: '15px', right: '15px', cursor: 'pointer', marginTop: '1.25rem' }} />
      </Link>
    </>
  );
};

export default ExperimentReportMobile;
