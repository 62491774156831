import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import service from "../stage/stageService";
import { Chip, Box } from '@mui/material'
import { ToastContainer, toast } from "react-toastify";
import Sidebar from '../sidenav/Sidenav';
import { FormControl, Select, InputLabel, Checkbox, OutlinedInput, ListItemText, MenuItem, ListSubheader, TextField } from '@mui/material';

import axios from "axios";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { useLocalStorage } from "@uidotdev/usehooks";

import { ReactComponent as UserIcon } from '../assets/icons/UserIcon.svg';
import { ReactComponent as Hemberger } from '../assets/icons/Hemberger.svg';

const SkillReportMobile = () => {
  const [missions, setMissions] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [token, setToken] = useLocalStorage('token', null);
  const [fromDate, setFromDate] = useState(dayjs("01/01/1995"))
  const [selectedSkillName, setSelectedSkillName] = useState([])
  const [toDate, setToDate] = useState(dayjs());
  const [filter, setFilter] = useState("");
  const [SkillStagePair, setSkillStagePair] = useState([]);
  const [selectedSkillObj, setSelectedSkillObj] = useState([])
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState([]);
  const [selectedMission, setSelectedMission] = useState([]);
  const [reports, setReports] = useState([]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(12);

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };
  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/mission-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReports(response.data.data);
      } else {
        console.error("Error fetching reports:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };
  const handleExportClick = async () => {
    // const toDate1 = new Date(toDate).toISOString().split('T')[0];
    // const fromDate1 = new Date(fromDate).toISOString().split('T')[0];
    if (selectedSkill.length === 0) {
      toast.error("No skill selected");
      return;
    }
    // console.log(`${filter} filter, ${selectedMission} selectedMission
    //   , ${selectedSkill} selectedSkill , ${selectedStage} selectedStage, ${fromDate1} fromDate, 
    //   ${toDate1} toDate`);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/export-missions`,
        {
          name: filter,
          mission_id: selectedMission,
          skill_id: selectedSkill,
          stage_id: selectedStage,
          fromDate: fromDate,
          toDate: toDate
        },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "MissionAttempts.xlsx");
      document.body.appendChild(link);
      link.click();
      console.log("File exported successfully");
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };
  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };
  const handleSkillChange = (event) => {
    const selectedSkillId = event.target.value;
    // console.log(selectedSkillId);
    let updatedSelectedSkills = [...selectedSkillObj];
    const skillIndex = updatedSelectedSkills.findIndex(
      (obj) => obj.id === selectedSkillId[selectedSkillId.length - 1].id
    );

    if (skillIndex !== -1) {
      updatedSelectedSkills.splice(skillIndex, 1); // Remove the skill
    } else {
      updatedSelectedSkills.push(selectedSkillId[selectedSkillId.length - 1]); // Add the skill
    }
    let updatedSelectedSkillIds = updatedSelectedSkills?.map((skill) => skill.id);
    let updatedSelectedSkillNames = updatedSelectedSkills?.map((skill) => skill.name);

    setSelectedSkillObj(updatedSelectedSkills);
    setSelectedSkill(updatedSelectedSkillIds);
    setSelectedSkillName(updatedSelectedSkillNames);
    setSelectedStage([]);
    fetchStages(updatedSelectedSkillIds);
  };

  const handleStageChange = (event) => {
    const selectedStageId = event.target.value;
    // console.log(event.target.value);
    setSelectedStage(selectedStageId);
    // console.log(stages);
    fetchMissions(selectedStageId);
    setSelectedMission([])
  };

  const fetchMissions = async (stageId) => {
    console.log(stageId);
    if (stageId.length === 0) {
      setSkillStagePair([]);
      setMissions([]);
      return;
    }
    const encoded = encodeURIComponent(JSON.stringify(stageId));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            value: encoded,
          },
        }
      );
      if (response.status === 200) {
        let SSPair = [];
        stages.skills?.map((skill) => {
          stages.stages?.map((stage) => {
            // console.log(stage);
            if (stage.skill_id === skill.skill_id) {
              SSPair.push({
                name: `${skill.skill_name}/${stage.stage_name}`,
                stage_id: stage.stage_id,
              });
            }
          });
        });
        // console.log(SSPair);
        setSkillStagePair(SSPair);
        setMissions(response.data.data);
      } else {
        console.error("Error fetching missions:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching missions:", error);
    }
  };
  const fetchStages = async (selectedSkill) => {
    if (selectedSkill.length === 0) {
      setStages([])
      return
    }
    try {

      const fetchedStages = await service.getSpecificStages(
        token,
        selectedSkill
      );
      setStages(fetchedStages);
    } catch (error) {
      console.error("Error fetching stages: ", error);
    }
  };

  // useEffect(()=>{
  //   console.log(skills);
  //   console.log(stages);
  //   console.log(missions);
  // },[skills,stages,missions])

  const handleReset = () => {
    setFilter("");
    setSelectedSkillObj([]);
    setSelectedSkill([]);
    setSelectedMission([]);
    setSelectedStage([]);
    setFromDate(null);
    setToDate(null);
  };
  useEffect(() => {
    fetchSkills();
    // fetchReports();
  }, [selectedSkill, filter]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '2.5rem 0.75rem 1.25rem 0.75rem' }}>
        <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: '36px', height: '36px', borderRadius: '18px', background: '#EAF1F7' }}>
          <Hemberger />
        </div>
        <Link to={'/profile'}>
          <UserIcon />
        </Link>
      </div>
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />
      <h4 style={{ margin: '1.313rem 0.25rem' }}>Skills Report</h4>
      <div className="container-fluid" style={{ marginTop: '1.25rem' }}>
        <div className="align-center-all full-height">
          <Card style={{ boxShadow: 'none' }}>
            <CardContent style={{ padding: '0' }}>
              <div className="filters">
                <div className="row">
                  <div className="col-md-3">

                    {/* Skills */}
                    <FormControl fullWidth className="mb-2.5" sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                      marginTop: '22px',
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}>
                      <InputLabel id="skills-label" style={{ color: "#6c6c6c" }}>Skill</InputLabel>
                      <Select
                        labelId="skill-label"
                        id="skill-select"
                        label="Skill"
                        value={selectedSkillObj}
                        multiple
                        onChange={handleSkillChange}
                        renderValue={() => "Skills"}
                      >
                        {/* <MenuItem value="">None</MenuItem> */}
                        {skills?.map((skill) => {
                          const temp = { name: skill.skill_name, id: skill.skill_id };
                          return <MenuItem key={skill.skill_id} value={temp}>
                            <Checkbox
                              // checked={selectedSkill.indexOf(skill.skill_id) > -1}
                              checked={selectedSkillObj.some(obj => obj.id === skill.skill_id)}
                            />
                            <ListItemText primary={skill.skill_name} />
                          </MenuItem>
                        })}
                      </Select>
                    </FormControl>

                    {/* Stages */}
                    <FormControl fullWidth className="mb-2.5" sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                      marginTop: '22px',
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}>
                      <InputLabel id="stages-label" style={{ color: "#6c6c6c" }}>Stages</InputLabel>
                      <Select
                        labelId="stages-label"
                        id="stages-select"
                        label="Stage"
                        value={selectedStage}
                        multiple
                        onChange={handleStageChange}
                        // onClick={handleStageChange}
                        renderValue={() => "Stages"}
                      >
                        {/* <MenuItem value="" disabled></MenuItem> */}
                        {Array.isArray(stages.skills) &&
                          stages.skills?.map((skill) => {
                            const elements = [];

                            // Add ListSubheader for the current skill
                            elements.push(
                              <ListSubheader key={`Skill${skill.skill_id}`}>
                                {skill.skill_name}
                              </ListSubheader>
                            );

                            // Add MenuItems for stages associated with the current skill
                            if (Array.isArray(stages?.stages)) {
                              stages.stages?.forEach((stage) => {
                                if (stage.skill_id === skill.skill_id) {
                                  elements.push(
                                    <MenuItem
                                      key={stage.stage_id}
                                      value={stage.stage_id}
                                    >
                                      <Checkbox
                                        checked={
                                          selectedStage.indexOf(stage.stage_id) >
                                          -1
                                        }
                                      />
                                      <ListItemText primary={stage.stage_name} />
                                    </MenuItem>
                                  );
                                }
                              });
                            }

                            return elements;
                          })}
                      </Select>
                    </FormControl>

                    {/* Mission */}
                    <FormControl fullWidth className="mb-2.5" sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                      marginTop: '22px',
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}>
                      <InputLabel id="mission-label" style={{ color: "#6c6c6c" }}>Mission</InputLabel>
                      <Select
                        labelId="mission-label"
                        id="mission-select"
                        label="Mission"
                        multiple
                        onChange={(e) => {
                          const selectedMissionId = e.target.value;
                          // console.log(selectedMissionId);
                          setSelectedMission(selectedMissionId);
                        }}
                        value={selectedMission}
                        renderValue={() => "Missions"}
                      >
                        {/* <MenuItem value="" disabled></MenuItem> */}
                        {SkillStagePair?.map((SSPair) => {
                          if (selectedStage.includes(SSPair.stage_id)) {
                            let element = [];
                            element.push(
                              <ListSubheader key={SSPair.name}>
                                {SSPair.name}
                              </ListSubheader>
                            );
                            // Iterate over missions array and generate MenuItems for missions associated with the current stage
                            missions.forEach((mission) => {
                              // console.log(mission.stage_id, SSPair.stage_id);
                              if (SSPair.stage_id === mission.stage_id) {
                                element.push(
                                  <MenuItem
                                    key={mission.mission_id}
                                    value={mission.mission_id}
                                  >
                                    <Checkbox
                                      checked={
                                        selectedMission.indexOf(
                                          mission.mission_id
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={mission.mission_name}
                                    />
                                  </MenuItem>
                                );
                              }
                            });
                            return element;
                          }
                          return null; // If stage_id is not included in selectedStage, return null or an empty array
                        })}
                      </Select>
                    </FormControl>

                    {/* Date From */}
                    <FormControl fullWidth className="mb-2.5" sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                      marginTop: '22px',
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From"
                          value={fromDate}
                          onChange={(value) => { setFromDate(dayjs(value).startOf('day')) }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl fullWidth className="mb-2.5" sx={{
                      width: '100%', '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          width: '100%',
                          boxShadow: 'none',
                        },
                      },
                      marginTop: '22px',
                    }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="To" value={toDate} onChange={(value) => { setToDate(dayjs(value).endOf('day')) }} />
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                  <div className="col-md-3">
                    <TextField
                      label="Name"
                      variant="outlined"
                      value={filter}
                      fullWidth
                      onChange={handleInputChange}
                      placeholder="Enter Name"
                      sx={{
                        width: '100%', '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            width: '100%',
                            boxShadow: 'none',
                          },
                        },
                        marginTop: '22px',
                      }}
                      style={{
                        boxShadow: '0px 1.74px 3.48px 0px #0000001A inset, 0px -1.74px 3.8px 0px #C8C7C740 inset, 0px 1.74px 3.48px 0px #0000001A'
                      }}
                      InputLabelProps={{
                        sx: { "&.Mui-focused": { color: "#A8A8A8" } },
                        required: false
                      }}
                    />
                    <div style={{ marginTop: "1rem", display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                      <Button
                        variant="contained"
                        className="mr-1 cancelMobileButtonStyles"
                        onClick={handleReset}
                        style={{ flex: '1' }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleExportClick}
                        className='addMobileButtonSytles'
                        style={{ flex: '1' }}
                      >
                        Export
                      </Button>
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                    <InputLabel id="skill-label">Skill</InputLabel>
                    <Select
                      labelId="skill-label"
                      id="skill-select"
                      label="SkillFilter"
                      multiple
                      value={selectedSkill}
                      // onChange={handleSkillChange}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selectedSkillName.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      disabled
                      fullWidth

                    > */}
                  {/* <MenuItem value="">None</MenuItem> */}
                  {/* {skills.map((skill) => (
                        <MenuItem key={skill.skill_id} value={skill.skill_id}>
                          <Checkbox
                            checked={selectedSkill.indexOf(skill.skill_id) > -1}
                          />
                          <ListItemText primary={skill.skill_name} />
                        </MenuItem>
                      ))}
                    </Select>

                  </div> */}
                </div>
                {/* <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Class</th>
                    <th>Earned/Total Score</th>
                    <th>Skill ID</th>
                    <th>Stage ID</th>
                    <th>Mission id</th>
                  </tr>
                </thead>
                <tbody>
                  {reports
                    ?.filter(
                      (report) =>
                        (!filter ||
                          report.name
                            ?.toLowerCase()
                            .includes(filter?.toLowerCase()) ||
                          filter === "") &&
                        (!selectedMission ||
                          selectedMission?.includes(report.mission_id) ||
                          selectedMission.length === 0) &&
                        (!selectedSkill ||
                          selectedSkill?.includes(report.skill_id) ||
                          selectedSkill.length === 0) &&
                        (!selectedStage ||
                          selectedStage?.includes(report.stage_id) ||
                          selectedStage.length === 0) 
                          &&
                          (!toDate || dayjs(report.end_time).isSameOrBefore(toDate)) &&
                          (!fromDate || dayjs(report.end_time).isSameOrAfter(fromDate))
                    )
                    .map((filteredReport) => {
                      // console.log(filteredReport);
                      // console.log(dayjs(filteredReport.end_time));
                      // console.log(dayjs(filteredReport.end_time).isSameOrAfter(toDate));
                      return (<tr   key={filteredReport.attempt_id}>
                        <td>{filteredReport.attempt_id}</td>
                        <td>{filteredReport.name}</td>
                        <td>{filteredReport.user_class}</td>
                        <td>{`${filteredReport.total_correct}/${filteredReport.total_questions}`}</td>
                        <td>{filteredReport.skill_id}</td>
                        <td>{filteredReport.stage_id}</td>
                        <td>{filteredReport.mission_id}</td>
                      </tr>
                      )
                    })}
                </tbody>
              </table> */}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <Link to={'/report'}>
        <ArrowCircleLeftIcon sx={{ fill: '#37446f' }} style={{ position: 'fixed', bottom: '15px', right: '15px', cursor: 'pointer', marginTop: '1.25rem' }} />
      </Link>
    </>
  )
}

export default SkillReportMobile