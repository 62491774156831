import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Button } from '@mui/material';

const Question = ({ onQuestionsAdded, questionType, missionId }) => {
  const [token,setToken]=useLocalStorage('token',null)
  const [selectedFile, setSelectedFile] = useState(null);

  const handleAdd = async () => {
    try {
      const formData = new FormData();
      formData.append('excelFile', selectedFile);
      formData.append('questionType', questionType);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/exercise/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      const insertedQuestionIds = response.data.insertedQuestionIds;
      onQuestionsAdded(insertedQuestionIds);

      toast.success('Questions added successfully');
    } catch (error) {
      console.error('Error adding questions:', error);
      toast.error(`Error adding questions: ${error.message}`);
    }
  };

  // const handleDownload = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/exercise/download/${missionId}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       responseType: 'blob',
  //     });

  //     const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'questions.xlsx';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     toast.success('Questions downloaded successfully');
  //   } catch (error) {
  //     console.error('Error downloading questions:', error);
  //     toast.error(`Error downloading questions: ${error.message}`);
  //   }
  // };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append('excelFile', selectedFile);

      await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/exercise/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      const insertedQuestionIds = [];
      console.log('Questions updated successfully');
      onQuestionsAdded(insertedQuestionIds);

      toast.success('Questions updated successfully');
    } catch (error) {
      console.error('Error updating questions:', error);
      toast.error(`Error updating questions: ${error.message}`);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <input type="file" onChange={handleFileChange} />
      <div>
      <Button onClick={handleAdd} className='filled-button mr-2'>Add Questions</Button>
      <Button onClick={handleUpdate} className='filled-button'>Update Questions</Button>
      </div>
    </div>
  );
};

export default Question;