import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import service from "../stage/stageService";
import BattleReportMobile from "./BattleReportMobile";
import isDesktop from "../isDesktop";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useLocalStorage } from "@uidotdev/usehooks";
const BattleReport = () => {
  const isDesktopDevice = isDesktop();
  const [battles, setBattles] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState("");
  const [token,setToken] = useLocalStorage('token',null);
  const [filter, setFilter] = useState("");
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedBattle, setSelectedBattle] = useState("");
  const [reports, setReports] = useState([]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(10);

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/battle-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReports(response.data.data);
      } else {
        console.error("Error fetching reports:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleExportClick = async () => {
    if(!selectedSkill) {
      console.log("No skill selected")
      return
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/export-battle`,
        {
          name: filter,
          battle_id: selectedBattle,
          skill_id: selectedSkill,
          stage_id: selectedStage
        },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BattleAttempts.xlsx");
      document.body.appendChild(link);
      link.click();
      console.log("File exported successfully");
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const handleSkillChange = (event) => {
    const selectedSkillId = event.target.value;
    setSelectedSkill(selectedSkillId);
    fetchStages(selectedSkillId);
  };

  const fetchBattles = async (stageId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/battle/all/${stageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setBattles(response.data.data);
      } else {
        console.error("Error fetching battles:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching battles:", error);
    }
  };

  const fetchStages = async (selectedSkill) => {
    try {
      const fetchedStages = await service.getStages(token, selectedSkill);
      setStages(fetchedStages);
    } catch (error) {
      console.error("Error fetching stages: ", error);
    }
  };

  const handleReset = () => {
    setFilter("");
    setSelectedSkill("");
    setSelectedBattle("");
    setSelectedStage("");
  };

  useEffect(() => {
    if(isDesktopDevice) {
      fetchSkills();
      // fetchReports();
    }
  }, [selectedSkill, filter]);

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2>You are unauthorized to access this page</h2>
      </div>
    )
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
  
    const amPm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amPm}`;
    return formattedTime;
  }

  return (
    <>
    {
      isDesktopDevice ? (
        <div className="container-fluid mt-3 p-0">
      <div className="align-center-all full-height">
        <Card sx={{boxShadow: 'none'}}>
          <CardContent>
            <div className="filters">
            <div className="sub-title">Battle Report</div>
              <div
                style=
                {{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <div className="fieldContainer">
                  <div className="leftFields">
                    <FormControl
                      fullWidth
                      className="mb-3 customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        },
                        width: '32.051vw'
                      }}
                    >
                    <InputLabel id="skill-labels">Skill</InputLabel>
                    <Select
                      labelId="skill-label"
                      id="skill-select"
                      label="Skill"
                      value={selectedSkill}
                      onChange={handleSkillChange}
                    >
                      <MenuItem value="">None</MenuItem>
                      {skills.map((skill) => (
                        <MenuItem key={skill.skill_id} value={skill.skill_id}>
                          {skill.skill_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                      fullWidth
                      className="mb-3 customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        },
                        width: '32.051vw'
                      }}
                    >
                    <InputLabel id="stages-label">Stages</InputLabel>
                    <Select
                      labelId="stages-label"
                      id="stages-select"
                      label="Stage"
                      value={selectedStage}
                      onChange={(e) => {
                        const selectedStageId = e.target.value;
                        setSelectedStage(selectedStageId);
                        fetchBattles(selectedSkill, selectedStageId);
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {Array.isArray(stages.stages) &&
                        stages.stages.map((stage) => (
                          <MenuItem key={stage.stage_id} value={stage.stage_id}>
                            {stage.stage_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  </div>
                  <div className="rightFields">
                  <FormControl
                      fullWidth
                      className="mb-3 customTextfield"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'transparent',
                          },
                          '&:hover fieldset': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            boxShadow: 'none',
                          },
                        },
                        width: '32.051vw'
                      }}
                    >
                    <InputLabel id="battle-label">Battle</InputLabel>
                    <Select
                      labelId="battle-label"
                      id="battle-select"
                      label="Battle"
                      onChange={(e) => {
                        const selectedBattleId = e.target.value;
                        setSelectedBattle(selectedBattleId);
                      }}
                      value={selectedBattle}
                    >
                      <MenuItem value="">None</MenuItem>
                      {battles.map((battle) => (
                        <MenuItem
                          key={battle.battle_id}
                          value={battle.battle_id}
                        >
                          {battle.battle_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Name"
                    variant="outlined"
                    value={filter}
                    fullWidth
                    onChange={handleInputChange}
                    placeholder="Enter Name"
                    className="mb-3 customTextfield"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                      width: '32.051vw'
                    }}
                  />
                  </div>
                </div>
                <div>
                      <Button
                        variant="outlined"
                        className="mr-1 outlined-button"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleExportClick}
                        className="filled-button"
                      >
                        Export
                      </Button>
                    </div>
                    </div>

                {
                  reports.length > 0 && (
                  <table className="table mt-5">
                  <thead className="table-head">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Class</th>
                      <th>Earned/Total Score</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports
                      ?.filter(
                        (report) =>
                          (!filter ||
                            report.name
                              ?.toLowerCase()
                              .includes(filter?.toLowerCase()) ||
                            filter === "") &&
                          (!selectedBattle ||
                            report.battle_id
                              ?.toString()
                              .includes(selectedBattle) ||
                            selectedBattle === "") &&
                          (!selectedSkill ||
                            report.skill_id
                              ?.toString()
                              .includes(selectedSkill) ||
                            selectedSkill === "") &&
                          (!selectedStage ||
                            report.stage_id
                              ?.toString()
                              .includes(selectedStage) ||
                            selectedStage === "")
                      )
                      .map((filteredReport) => (
                        <tr key={filteredReport.attempt_id}>
                          <td>{filteredReport.attempt_id}</td>
                          <td>{filteredReport.name}</td>
                          <td>{filteredReport.user_class}</td>
                          <td>{`${filteredReport.total_correct}/${filteredReport.total_questions}`}</td>
                          <td>{formatTime(filteredReport.start_time)}</td>
                          <td>{formatTime(filteredReport.end_time)}</td>
                        </tr>
                      ))}
                  </tbody>
                  </table> )
    }
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
      ) : (
        <BattleReportMobile />
      )
    }
    </>
  );
};
export default BattleReport;
