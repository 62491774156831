import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";
import smartsaIcon from "../assets/report/smartsa_logo.svg";
import envelope from "../assets/report/Envelope.svg";
import phone from "../assets/report/Phone.svg";
import website from "../assets/report/Website.svg";
import Styles from "./Report.module.css";
import { Card, CardContent, Container, Typography, Grid } from "@mui/material";
import html2pdf from "html2pdf.js";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const ScoreBarChart = ({ scores }) => {
  const data = Object.keys(scores).map((category) => {
    const categoryName = category.split("_").join(" ");
    return {
      category: categoryName,
      score: (scores[category].correct / scores[category].total) * 100,
    };
  });

  const weaknessCategories = data.filter((entry) => entry.score <= 50);
  const averageCategories = data.filter(
    (entry) => entry.score > 50 && entry.score <= 75
  );
  const strengthCategories = data.filter((entry) => entry.score > 75);

  const cardStyle = {
    marginBottom: "10px",
    height: "200px",
    borderRadius: "20px",
    border: "1px solid #cccccc",
  };

  const renderCategoryList = (categories) => (
    <ul style={{ marginTop: "8px", width: 30, whiteSpace: "pre-line" }}>
      {categories.map((category, index) => (
        <li key={`category-${index}`}>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ padding: 0 }}
          >
            {category.category}
          </Typography>
        </li>
      ))}
    </ul>
  );

  return (
    <div>
      <div
        style={{
          backgroundColor: "#464646",
          borderRadius: "20px",
          padding: "1.25rem",
          marginBottom: "0.625rem",
          marginTop: "0.625rem",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ResponsiveContainer width={950} height={200}>
          <BarChart
            data={data}
            margin={{ top: 30, right: 20, bottom: 10, left: 20 }}
          >
            <XAxis
              dataKey="category"
              tick={{ fill: "white", fontSize: "0.75rem", width: "20px" }}
              interval={0}
              tickLine={{ dy: 200 }}
            />
            <YAxis hide />
            <Tooltip isAnimationActive={false} />
            <Bar dataKey="score" barSize={30} animationDuration={0}>
              <LabelList
                dataKey="score"
                position="top"
                formatter={(value) => `${Math.round(parseFloat(value))}%`}
                style={{ fill: "#ffffff", fontSize: "14px"}}
              />
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.score <= 50
                      ? "#EA2329"
                      : entry.score > 50 && entry.score <= 75
                      ? "#F7981D"
                      : "#01589D"
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <Grid container spacing={2} style={{ display: "flex" }}>
        <Grid item xs={4}>
          <Card sx={{ ...cardStyle }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                backgroundColor: "#EA2329",
                textAlign: "center",
                color: "#ffffff",
                padding: "8px",
                fontSize: "14px",
              }}
            >
              Weakness
            </Typography>
            <CardContent sx={{ padding: 0 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  {renderCategoryList(
                    weaknessCategories.slice(
                      0,
                      Math.ceil(weaknessCategories.length / 2)
                    )
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderCategoryList(
                    weaknessCategories.slice(
                      Math.ceil(weaknessCategories.length / 2)
                    )
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ ...cardStyle }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                backgroundColor: "#F7981D",
                textAlign: "center",
                color: "#ffffff",
                padding: "8px",
                fontSize: "14px",
              }}
            >
              Average
            </Typography>
            <CardContent sx={{ padding: 0 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  {renderCategoryList(
                    averageCategories.slice(
                      0,
                      Math.ceil(averageCategories.length / 2)
                    )
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderCategoryList(
                    averageCategories.slice(
                      Math.ceil(averageCategories.length / 2)
                    )
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ ...cardStyle }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                backgroundColor: "#01589D",
                textAlign: "center",
                color: "#ffffff",
                padding: "8px",
                fontSize: "14px",
              }}
            >
              Strength
            </Typography>
            <CardContent sx={{ padding: 0 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  {renderCategoryList(
                    strengthCategories.slice(
                      0,
                      Math.ceil(strengthCategories.length / 2)
                    )
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderCategoryList(
                    strengthCategories.slice(
                      Math.ceil(strengthCategories.length / 2)
                    )
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const Report = () => {
  const [reportData, setReportData] = useState(null);
  const [hasDownloaded,setHasDownloaded]=useState(false)
  const {userId, attemptId}=useParams()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/experiment-report/${userId}/${attemptId}`
        );
        console.log(response.data.data);
        if (response.status==200) {
          const data = await response.data;
          setReportData(data.data[0]);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId, attemptId]);

  useEffect(() => {
    if (reportData && !hasDownloaded) {
      setHasDownloaded(true)
      console.log("Hello")
      downloadReport();
    }
  }, [reportData,userId]);

  let submittedArray = [];
  try {
    submittedArray = JSON.parse(reportData.submitted);
  } catch (error) {
    console.error('Error parsing submitted data:', error);
    return null;
  }

  const attemptedQuestions = submittedArray.filter(item => item.submittedAnswer !== undefined);

  const totalAttemptedQuestions = attemptedQuestions.length;

  const downloadReport = async () => {
    try {
        const element = document.getElementById("pdf-container");
        const pdfOptions = {
          margin: 10,
          filename: "report.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
        };

        await html2pdf(element, pdfOptions);
        // setShowReport(false)
        // navigate(`/user-exp-report`);
      setTimeout(() => {
        window.close( )
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  if (!reportData) {
    return <p>Loading...</p>;
  }

  const questionCount = JSON.parse(reportData.question_count);
  const dobUTC = reportData?.dob;
  const concepts = JSON.parse(reportData.concepts);
  console.log(reportData);

  let sortedQuestionCount = {};


  if (concepts) {
    concepts.forEach(concept => {
      if (questionCount[concept]) {
        sortedQuestionCount[concept] = questionCount[concept];
      }
    }); 
 

  for (const key in questionCount) {
    if (!concepts.includes(key)) {
      sortedQuestionCount[key] = questionCount[key];
    }
  }
  }else{
    sortedQuestionCount=questionCount
  }

  const dobDate = new Date(dobUTC);

  dobDate.setHours(dobDate.getHours() + 5);
  dobDate.setMinutes(dobDate.getMinutes() + 30);

  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const adjustedDOB = dobDate.toLocaleDateString("en-US", options);

  const testUTC = reportData?.end_time;

  const testDate = new Date(testUTC);

  testDate.setHours(dobDate.getHours() + 5);
  testDate.setMinutes(dobDate.getMinutes() + 30);

  const adjustedTestDate = testDate.toLocaleDateString("en-US", options);

  const convertTimeToMinutes = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    let timeString = '';
  
    if (hours > 0) {
      timeString += `${hours} hr${hours > 1 ? 's' : ''} `;
    }
  
    timeString += `${minutes} min${minutes > 1 ? 's' : ''} `;
  
    if (seconds > 0) {
      timeString += `${seconds} sec${seconds > 1 ? 's' : ''}`;
    }
  
    return timeString.trim();
  };

  return (
    <div className={Styles.container} id="pdf-container">
      <Container>
        <div className={Styles.navbar}>
          <div className={Styles.left}>
            <img src={smartsaIcon} alt="Smartsa Icon" />
          </div>
          <div className={Styles.right}>
            <img src={phone} alt="Phone" />
            <p>+91-9503808388</p>
            <img src={envelope} alt="Envelope" />
            <p>info@smartsa.in</p>
            <img src={website} alt="Website" />
            <p>smartsa.in</p>
          </div>
        </div>
        <div className={Styles.page}>
          <div className={Styles.title}>
            <Typography sx={{padding: 0}}>Test Report</Typography>
          </div>
          <div className={Styles.box}>
            <div className={Styles.userinfo}>
              <div className={Styles.infoBox}>
                <div>Name: {reportData.name}</div>
                <div>Email: {reportData.email}</div>
                <div>Username: {reportData.username}</div>
              </div>
              <div className={Styles.infoBox}>
                <div>Mobile: {reportData.mobile}</div>
                <div>Test Name: {reportData.experiment_name}</div>
                <div>Test Date: {adjustedTestDate}</div>
              </div>
              <div className={Styles.infoBox}>
                <div>Time Spent: {convertTimeToMinutes(reportData.elapsed_time)}</div>
                <div>Attempted: {totalAttemptedQuestions}/{reportData.total_questions}</div>
                <div>Accuracy: {(reportData.total_correct * 100) / reportData.total_questions}%</div>
              </div>

            </div>
            <div className={Styles.scoreBox}>
                <div>Total Score</div>
                <div>{reportData.total_correct}/{reportData.total_questions}</div>
              </div>
          </div>
          <div className={Styles.chartContainer}>
            <ScoreBarChart scores={sortedQuestionCount} />
          </div>
          <hr className={Styles.divider} />
          <p className={Styles.footer}>
            202, Vaidehi Saket, Baner-Pashan Link Road, Pashan, Maharashtra -
            411021
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Report;