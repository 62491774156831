import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DialogContent } from '@mui/material';

const AddUpdateDialog = ({ open, handleClose, formFields, newStage, setNewStage, handleSubmit }) => {
  const fieldsToExclude = ['stage_id', 'skill_id', 'is_active', 'created_at', 'updated_at'];

  const filteredFields = formFields.filter(field => !fieldsToExclude.includes(field));

  const handleDialogSubmit = async () => {
     await handleSubmit();
     handleClose();
    
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: '35.128vw',
          padding: '1rem',
          borderRadius: '0.513vw',
          boxShadow: '4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80'
        }
      }}
    >
      <DialogTitle>{newStage.stage_id ? 'Update' : 'Add'} Stage</DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {filteredFields.map((field) => (
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
              marginBottom: "0.625rem",
            }}
            className='customTextfield'
            type={`${field.includes("order")?"Number":""}`}
            key={field}
            label={field}
            name={field}
            value={newStage[field] || ''}
            onChange={(e) =>
              setNewStage((prev) => ({
                ...prev,
                [field]: e.target.value,
              }))
            }
          />
        ))}
      </DialogContent>
      <div className='dialog-actions-box'>
        <Button
          variant="outlined"
          onClick={handleClose}
          className="mr-1 outlined-button"
        >
          Cancel
        </Button>
        <Button variant="contained" className='filled-button' onClick={handleDialogSubmit}>
          {newStage.stage_id ? 'Update' : 'Add'} Stage
        </Button>
        </div>
    </Dialog>
  );
};

export default AddUpdateDialog;