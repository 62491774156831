import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { CardContent, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import navLink from "../navLinks/Navlinks";
import roleService from "./roleService";
import AddRoleDialog from "./AddRoleDialog";
import UpdateRoleDialog from "./UpdateRoleDialog";
import './Roles.css'
import { useLocalStorage } from "@uidotdev/usehooks";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { toast } from "react-toastify";
import isDesktop from "../isDesktop";
import RolesMobile from "./RolesMobile";

const Roles = () => {
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [allSkills, setAllSkills] = useState([]);
  const [formValues, setFormValues] = React.useState({ username: '', email: '', role: '', phone: '', order_id: '' });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    console.log({...formValues});
  };
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState({ tabId: "", roleName: "" });
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [selectedRoleForUpdate, setSelectedRoleForUpdate] = useState(null);

  const [token, setToken] = useLocalStorage('token', null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(8);

  const fetchRoles = async () => {
    try {
      const activeRoles = await roleService.getRoles(token);
      setRoles(activeRoles);
    } catch (error) {
      console.error("Error fetching roles:", error.response);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);
  console.log(roles);

  const handleUpdate = (roleId) => {
    // console.log(roleId);

    setSelectedTabs(roles && JSON.parse(roles.find((role) => role.role_id === roleId).tab_id));
    const selectedRole = roles.find((role) => role.role_id === roleId);
    setSelectedRoleForUpdate(selectedRole);
    console.log(selectedRole);
    setUpdateDialogOpen(true);
    // setOpenDialog(true)
  };
  // console.log(selectedTabs);

  const handleUpdateSave = () => {
    const role = selectedRoleForUpdate.role_id;
    const tabIds = selectedTabs;
    // console.log(role, tabIds, " ye hua update"); // O/P: 1, (4) [1, 14, 13, 21] ' ye hua update' 

    handleCloseUpdateDialog();
    roleService
      .updateRole(token, role, tabIds)
      .then((response) => {
        console.log("Role updated successfully:", response);
        toast.success("Role updated successfully:", response);
        handleCloseUpdateDialog();
        fetchRoles();
      })
      .catch((error) => {
        console.error("Error updating role:", error);
        toast.error("Error updating role:", error);
      });
  };

  const handleAdd = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
    setSelectedTabs([])
  };

  const handleCloseUpdateDialog = () => {
    setUpdateDialogOpen(false);
    setSelectedTabs([]);
  };

  const handleSave = () => {
    if (!newRole.roleName || selectedTabs.length === 0) {
      console.error("Please enter Role Name and select at least one tab");
      toast.error("Please enter Role Name and select at least one tab");
      return;
    }

    handleCloseAddDialog();
    roleService
      .createRole(token, {
        role: newRole.roleName,
        tabIds: selectedTabs,
      })
      .then((response) => {
        console.log("Role created successfully:", response);
        toast.success("Role created successfully:", response)
        handleCloseAddDialog();
        fetchRoles();
      })
      .catch((error) => {
        console.error("Error creating role:", error);
        toast.error("Error creating role:", error);
      });
  };
  console.log(selectedTabs);
  
  const handleTabToggle = (tabId) => {
    console.log(tabId, " tabId");
    // console.log(selectedTabs);
    
    const updatedTabs = selectedTabs.includes(tabId)
      ? selectedTabs.filter((id) => id !== tabId)
      : [...selectedTabs, tabId];

    setSelectedTabs(updatedTabs);
  };

  const [open, setOpen] = React.useState(false);
  const handleDelete = (roleId) => {
    setOpen(true);
    setSelectedRole(roleId);
  };
  const handleConfirmDelete = () => {
    setOpen(false);
    if (selectedRole) {
      roleService
        .deleteRole(token, selectedRole)
        .then((response) => {
          console.log("Role deleted successfully:", response);
          toast.success("Role deleted successfully:", response);
          fetchRoles();
        })
        .catch((error) => {
          console.error("Error deleting role:", error);
          toast.error("Error deleting role:", error);
        });
    }
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    // setOpenDialog(false)
    setUpdateDialogOpen(false);
    setSelectedTabs([]);
  }
  const isDesktopDevice = isDesktop();
  return (
    <>
      {
        isAuthorized ? (
          isDesktopDevice ? (
            <div className="container-fluid" style={{ paddingTop: '4rem' }}>
              <div className="align-center-all full-height">
                <Card elevation={3} className="col-md-12 p-3" style={{ boxShadow: 'none' }}>
                  <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                      <h2>Roles</h2>
                      <Button
                        variant="contained"
                        color="primary"
                        className="button-two"
                        onClick={handleAdd}
                        style={{ width: '15.385vw', background: '#37446F', height: '2.564vw' }}
                      >
                        Add
                      </Button>
                    </div>
                    <table className="table">
                      <thead style={{ height: '44px', borderRadius: '4px !important', background: 'rgba(234, 241, 247, 1)', boxShadow: '2px 2px 4px 0px rgba(174, 174, 192, 0.5)' }}>
                        <tr>
                          <th style={{ width: '6.41vw' }}>S. No.</th>
                          <th style={{ textAlign: 'left' }}>Name</th>
                          <th style={{ width: '55.769vw' }}>Role</th>
                          <th style={{ textAlign: 'left', width: '17vw' }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roles.map((role) => (
                          <tr key={`${String(role.role_id)}-${String(role.role)}`}>
                            <td>{role.role_id}</td>
                            <td style={{ textAlign: 'left' }}>{role.role}</td>
                            <td>{role.tab_id}</td>
                            <td style={{ textAlign: 'left' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleUpdate(role.role_id)}
                                className="mr-2"
                                style={{ background: '#CBEBF6', color: 'black', boxShadow: '2px 2px 4px 0px #AEAEC080', textTransform: 'none', width: '7.179vw', height: '2.051vw' }}
                              >
                                Update
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleDelete(role.role_id)}
                                className="mr-2"
                                style={{ background: '#FFD6D2', color: 'black', boxShadow: '2px 2px 4px 0px #AEAEC080', textTransform: 'none', width: '7.179vw', height: '2.051vw' }}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <AddRoleDialog
                      isOpen={isAddDialogOpen}
                      onClose={handleCloseAddDialog}
                      onSave={handleSave}
                      onTabToggle={handleTabToggle}
                      selectedTabs={selectedTabs}
                      newRole={newRole}
                      setNewRole={setNewRole}
                      navLink={navLink}
                    />
                    {/* Update */}
                    <UpdateRoleDialog
                      isOpen={isUpdateDialogOpen}
                      onClose={handleCloseDialog}
                      onSave={handleUpdateSave}
                      onTabToggle={handleTabToggle}
                      selectedTabs={selectedTabs}
                      selectedRoleForUpdate={selectedRoleForUpdate}
                      navLink={navLink}
                    />
                  </CardContent>

                  {/* Delete Dialog Box */}
                  <Dialog
                    open={open}
                    onClose={(prev) => !prev}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ textAlign: 'center' }}
                  >
                    <ErrorOutlineIcon style={{ color: 'red', fontSize: '32px', margin: '12px auto' }} />
                    <DialogTitle id="alert-dialog-title" style={{ fontSize: '18px', fontWeight: '500', lineHeight: '0' }}>
                      {"Are you Sure?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description" style={{ fontSize: '12px' }}>
                        You won't be able to revert this!
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpen(false)} style={{ textTransform: 'none', width: '120px', height: '36px', border: '2.03px solid #37446F', borderRadius: '5.85px', background: 'none', color: 'black' }}>Cancel</Button>
                      <Button onClick={handleConfirmDelete} style={{ textTransform: 'none', width: '120px', height: '36px', borderRadius: '5.85px', background: '#37446F', color: 'white' }} autoFocus>
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Card>
              </div>
            </div>
          ) : (
            <RolesMobile />
          )
        ) : (
          <h2 style={{marginTop: '5%'}}>You are unauthorized to access this page.</h2>
        )
      }
    </>
  );
};

export default Roles;
