import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import axios from "axios";
import WarExercise from "./warExercise";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Grid } from "@mui/material";
import { MenuItem, MenuList } from "@mui/material";

const War = ({ stages = [] }) => {
  const { skillId } = useParams();
  const [wars, setWars] = useState([]);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [formData, setFormData] = useState({ warId: "", warName: "" });
  const [insertedQuestionIds, setInsertedQuestionIds] = useState([]);
  const [missions, setMissions] = useState([]);
  const [selectedMissions, setSelectedMissions] = useState([]);
  const [isReplicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [replicateFormData, setReplicateFormData] = useState({
    newSkillId: "",
    newWarName: "",
  });
  const [currentWarId, setCurrentWarId] = useState(null);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(4);
  useEffect(() => {
    fetchWars();
  }, [skillId]);

  const [token, setToken] = useLocalStorage("token", null);

  const fetchWars = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/all/${skillId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setWars(response.data.data);
      } else {
        console.error("Error fetching wars:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching wars:", error);
    }
  };

  const inputFields = [
    { label: "War Name", name: "war_name" },
    { label: "War Order", name: "war_order", type: "number" },
    { label: "No. of Questions", name: "no_of_questions", type: "number" },
    { label: "Marks/Question", name: "marks_per_question", type: "number" },
    { label: "Negative Marks", name: "negative_marks", type: "number" },
    { label: "Max Time", name: "max_time", type: "time" },
    { label: "Coin Type", name: "coin_type" },
    { label: "Clear Score", name: "clear_score", type: "number" },
    { label: "Instructions", name: "instructions", type: "multiline" },
    { label: "War ID", name: "war_id", type: "number", disabled: true },
    { label: "Description", name: "description", type: "multiline" },
    // { label: "Question Type", name: "questionType" },
    { label: "Specialization ID", name: "specialization_id", type: "number" },
  ];

  const handleAdd = () => {
    setFormData({ warId: "", warName: "" });
    setAddDialogOpen(true);
  };

  const handleDownload = async (token, warId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/warExercise/download/${warId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'questions.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Questions downloaded successfully');
    } catch (error) {
      console.error('Error downloading questions:', error);
      toast.error(`Error downloading questions: ${error.message}`);
    }
  };

  const onDownloadQuestions = (tempWarId) => {
    if (tempWarId && token) {
      handleDownload(token, tempWarId);
    } else {
      toast.error('No war ID or token available');
    }
  };
  
  const fetchMissions=async()=>{
    try {
      const stageIds=stages?.map(val=>val.stage_id)
      const response =await axios.get( `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/data/${skillId}/${stageIds}`)

      console.log(response.data.data);
      setMissions(response.data.data)

    } catch (error) {
      toast.error(error.response.data.message)
      console.error(error);
      
      
    }
  }

  useEffect(()=>{

  },[])

  const handleEdit = (warId) => {
    const warToEdit = wars.find((war) => war.war_id === warId);
    console.log(warToEdit);

    setFormData({
      war_id: warToEdit.war_id,
      war_name: warToEdit.war_name,
      questionType: warToEdit.question_type || "lorem",
      war_order: warToEdit.war_order,
      marks_per_question: warToEdit.marks_per_question,
      negative_marks: warToEdit.negative_marks || 0,
      no_of_questions: warToEdit.no_of_questions,
      description: warToEdit.description,
      instructions: warToEdit.instructions,
      max_time: warToEdit.max_time,
      coin_type: warToEdit.coin_type,
      clear_score: warToEdit.clear_score,
      specialization_id: warToEdit.specialization_id,
    });
    const MissionDataArray=JSON.parse(warToEdit?.mission_question_data)||[]
    const selectedMissionData=MissionDataArray.map(val=>{
      const missionData=val?.stageMissions?.map(temp=>temp.missions);
      const questionData=val?.stageMissions?.map(temp=>temp.questions);

      return {
        id:val.id,
        missions:missionData,
        questions:questionData
      }

    })
    const stageId = stages.map((stage) => stage.stage_id);
    console.log(stageId);
    const stageArray = stageId.map((val) => {
      let tempMissions=[];
      let tempQuestions=[];
      const fetchedStage=selectedMissionData.find(val2=>val2.id===val)
      if (fetchedStage) {
        tempMissions=fetchedStage?.missions;
        tempQuestions=fetchedStage?.questions
      }
      return {
        id: val,
        missions: tempMissions,
        questions:tempQuestions
      };
    });
    setSelectedMissions(stageArray)
    setAddDialogOpen(true);
  };

  const handleReplicate = (warId) => {
    setCurrentWarId(warId);
    setReplicateDialogOpen(true);
  };

  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
  };

  const handleReplicateSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/replicate/war/${currentWarId}`,
        {
          newSkillId: replicateFormData.newSkillId,
          oldSkillId: skillId,
          newWarName: replicateFormData.newWarName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        fetchWars();
        setReplicateDialogOpen(false);
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const getMisssionsSkills = async () => {
    try {
      const stageId = stages.map((stage) => stage.stage_id);
      console.log(stageId);
      const stageArray = stageId.map((val) => {
        return {
          id: val,
          missions: [],
          questions:[]
        };
      });
      setSelectedMissions(stageArray);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${
          process.env.REACT_APP_API_KEY
        }/missions/data/${skillId}/${JSON.stringify(stageId)}`
      );

      setMissions(response.data.data);
    } catch (error) {
      console.error(error);
      console.error(error.response.data.message);
    }
  };

  useEffect(() => {
    console.log(stages);

    if (stages && stages.length !== 0) {
      getMisssionsSkills();
    }
  }, [stages]);

  const handleDelete = async (warId) => {
    try {
      confirmAlert({
        title: "Confirm Deletion",
        message: "Are you sure you want to delete this war?",
        buttons: [
          {
            label: "Yes",
            style: { backgroundColor: "#d32f2f", color: "white" },
            onClick: async () => {
              try {
                const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/delete/${warId}`;
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
                const response = await axios.delete(apiUrl, config);

                if (response.status === 200) {
                  fetchWars();
                  toast.success("War deleted successfully");
                } else {
                  console.error("Error deleting war:", response.data);
                }
              } catch (error) {
                toast.error(`Error deleting war: ${error.message}`);
              }
            },
          },
          {
            label: "No",
            style: { backgroundColor: "#1976d2", color: "white" },
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      toast.error(`Error deleting war: ${error.message}`);
    }
  };

  const initializatSSelectedMisisons=()=>{
    const stageId = stages.map((stage) => stage.stage_id);
      console.log(stageId);
      const stageArray = stageId.map((val) => {
        return {
          id: val,
          missions: [],
          questions:[]
        };
      });
      setSelectedMissions(stageArray);
  }

  const handleCloseDialog = () => {
    setInsertedQuestionIds([]);
    setAddDialogOpen(false);
    setCurrentWarId(null)
    initializatSSelectedMisisons()
  };

  const handleSave = async () => {
    try {
      const requiredFields = [
        "war_name",
        "war_order",
        // 'questionType',
        "marks_per_question",
        "no_of_questions",
        "description",
        "instructions",
        "max_time",
        "coin_type",
        "clear_score",
      ];

      const missingFields = requiredFields.filter((field) => !formData[field]);

      if (missingFields.length > 0) {
        toast.error(`Missing required fields: ${missingFields.join(", ")}`);
        return;
      }

      if (!isTimeValid(formData.max_time)) {
        toast.error('Invalid time format for Speed. Please use "hh:mm:ss".');
        return;
      }

      const apiUrl = formData.war_id
        ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/update/${formData.war_id}`
        : `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/add`;
      let totalSelectedQuestions=0;
        const missionQData=selectedMissions.map(stage=>{
          const stageMissions= stage?.missions?.map((mission,index)=>{
            if (mission!==-1 && Number(stage.questions[index])!==0) {
              totalSelectedQuestions+=Number(stage.questions[index])
              return {
                missions:mission,
                questions:Number(stage.questions[index])
              }
            }
            return null;
          }).filter(val=>val!==null)
          if (stageMissions.length===0) {
            return null;
          }
          return {
            id:stage.id,
            stageMissions:stageMissions}
        }).filter(val=>val!==null)
        console.log(missionQData);
        if (formData.no_of_questions< totalSelectedQuestions) {
          toast.error("Mission Questions less than total questions")
          return
        }
        if (formData.no_of_questions> totalSelectedQuestions) {
          toast.error("Mission Question Greater than total questions")
          return 
        }
      const payload = {
        war_name: formData.war_name,
        war_order: formData.war_order,
        question_type: formData.questionType || "lorem",
        marks_per_question: formData.marks_per_question,
        negative_marks: formData.negative_marks || 0,
        no_of_questions: formData.no_of_questions,
        description: formData.description,
        instructions: formData.instructions,
        max_time: formData.max_time,
        coin_type: formData.coin_type,
        // questionIds: insertedQuestionIds,
        missionQuestionData:missionQData,
        clear_score: formData.clear_score,
        specialization_id: formData.specialization_id,
        skillId: skillId,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios[formData.war_id ? "put" : "post"](
        apiUrl,
        payload,
        config
      );

      if (response.status === 201 || response.status === 200) {
        toast.success(`${response.data.message}`);
        fetchWars();
      } else {
        toast.error(`${response.data.message}`);
      }

      handleCloseDialog();
    } catch (error) {
      console.error(error);
      
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isTimeValid = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  if (!isAuthorized) {
    return <div className="container-fluid mt-5"></div>;
  }

  const selectedMissionChange = (e, sid, index) => {
    console.log(sid,index);
    
    setSelectedMissions((mission) => {
    const temp=  mission.map((prev) => {
        if (prev.id === sid) {
          const temp2 = prev.missions;
          temp2[index] = e.target.value;
          return {
            ...prev,
            missions: temp2,
          };
        }
        return prev;
      });

      return temp;
    });
  };

  const selectedMissionQuestionChange=(e,sid,index)=>{
    console.log(e,sid,index);
    
    setSelectedMissions(prev=>{
      const temp=prev.map(obj=>{
        if (obj.id===sid) {
          const temp2=obj.questions;
          temp2[index]=e.target.value;
          return {
            ...obj,
            questions:temp2
          }
        }
        return obj
      })
      return temp;
    })
  }

  const addMission=(e,sid)=>{
    setSelectedMissions(mission=>{
      const temp=mission.map(prev=>{
        if (prev.id===sid) {
          const temp2=prev.missions;
          const temp3=prev.questions;
          temp2.push(-1);
          temp3.push(0);
          return{
            ...prev,
            missions:temp2,
            questions:temp3
          }
        }
        return prev
      })
      return temp;
    })
  }

  const removeSelected=(sid,ind)=>{
    setSelectedMissions(mission=>{
      const temp=mission.map(prev=>{
        if (prev.id===sid) {
          const temp2=prev?.missions?.filter((val,index)=>index!==ind);
          const temp3=prev?.questions.filter((val,index)=>index!==ind);
          return{
            ...prev,
            missions:temp2,
            questions:temp3
          }
        }
        return prev
      })
      return temp;
    })
  }

  console.log(selectedMissions);
  

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        <Card style={{boxShadow: 'none'}} className="col-md-12 p-3">
          <CardContent>
            <div className="top-div">
            <h2>War</h2>
            <Button variant="contained" onClick={handleAdd} style={{float:'right'}} className="filled-button">
              Add
            </Button>
            </div>
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>War ID</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {wars.map((war) => (
                  <tr key={war.war_id}>
                    <td>{war.war_id}</td>
                    <td>{war.war_name}</td>
                    <td>
                      <Button
                        variant="contained"
                        onClick={() => handleEdit(war.war_id)}
                        className="mr-2 g-but"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleReplicate(war.war_id)}
                        className="mr-2 y-but"
                      >
                        Replicate
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleDelete(war.war_id)}
                        className="r-but"
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>

        <Dialog
          open={isAddDialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              width: "68.718vw",
              maxWidth: '100vw',
              padding: "1.5rem",
              borderRadius: "0.513vw",
              boxShadow:
                "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
            },
          }}
        >
          <DialogTitle style={{padding: 0}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>{formData.war_id ? "Update War" : "Add War"}</div>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: 0}}>
            <Grid container spacing={0}>
              {inputFields.map((field, index) => (
                <Grid item xs={6} key={field.name}>
                  <TextField
                    label={field.label}
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    type={`${field.label.includes("Order") ? "Number" : "text"}`}
                    disabled={field.disabled}
                    error={field.name === "max_time" && !isTimeValid(formData.max_time)}
                    placeholder={field.name === 'max_time' ? "hh:mm:ss" : field.label}
                    className="customTextfield"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      marginBottom: "0.625rem",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            {stages.map((stage, ind) => (
              <div className="mt-2">
                <div>
                <h6>Stage Name :{stage.stage_name}</h6>
                {selectedMissions?.find(val=>stage.stage_id === val.id)?.missions?.map((val, index) => {
                    return (
                      <div className="d-flex" style={{gap:"3px"}}>
                        <TextField
                          select
                          fullWidth
                          value={val}
                          onChange={(e)=>{selectedMissionChange(e,stage?.stage_id,index)}}
                        >
                          <MenuItem key={-1} value={-1}></MenuItem>
                          {missions?.filter(val=>val.stage_id===stage.stage_id)?.map((mission, index) => (
                            <MenuItem value={mission?.mission_id}>
                              {mission?.mission_name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                        value={selectedMissions?.find(val=>stage.stage_id === val.id)?.questions?.[index]}
                        label="No of Questions"
                        type="number"
                        onChange={(e)=>{selectedMissionQuestionChange(e,stage?.stage_id,index)}}
                        />
                        <Button
                        variant="contained"
                        color="error"
                        onClick={()=>{removeSelected(stage.stage_id,index)}}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  
                })}
                </div>
                <Button className="mt-2 filled-button" variant="filled" onClick={(e)=>{addMission(e,stage.stage_id)}}>
                  Add
                </Button>
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} className="outlined-button">
              Cancel
            </Button>
            <Button onClick={handleSave} className="filled-button" >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isReplicateDialogOpen}
          onClose={handleReplicateDialogClose}
          PaperProps={{
            style: {
              width: "35.128vw",
              maxWidth: "100vw",
              padding: "1.538vw",
              borderRadius: "0.513vw",
              overflow: 'hidden',
              boxShadow:
                "4px 4px 8px 0px #AEAEC099, box-shadow: -4px -4px 8px 0px #FFFFFF80",
            },
          }}
        >
          <DialogTitle sx={{padding: 0}}>Replicate War</DialogTitle>
          <DialogContent sx={{padding: 0}}>
            <TextField
              label="New Skill ID"
              name="newSkillId"
              value={replicateFormData.newSkillId}
              onChange={(e) =>
                setReplicateFormData({
                  ...replicateFormData,
                  newSkillId: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              type="text"
              className="customTextfield"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                marginBottom: "0.625rem",
              }}
            />
            <TextField
              label="New War Name"
              name="newWarName"
              value={replicateFormData.newWarName}
              onChange={(e) =>
                setReplicateFormData({
                  ...replicateFormData,
                  newWarName: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              type="text"
              className="customTextfield"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
                marginBottom: "0.625rem",
              }}
            />
          <DialogActions className="dialog-actions-box">
            <Button onClick={handleReplicateDialogClose} className="outlined-button" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleReplicateSubmit} className="filled-button" variant="contained">
              Replicate
            </Button>
          </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default War;
